import React from 'react'
import DataTableComponent from '../../components/datatable/DataTableComponent'
import { useSelector } from 'react-redux'

import {  Navigation} from '../../assets';

const columns = [
  {
    name: "ID",
    selector: (row) => row.id,
    width: "5%",
  },
  {
    name: "Property Name",
    selector: (row) => row.name,
    width: "15%",
  },
  {
    name: "Contact No.",
    selector: (row) => row.phone_number,
    width: "15%",

  },
  {
    name: "Email ID",
    selector: (row) => row.email,
    width: "25%",
  },
  {
    name: "Address",
    selector: (row) => <div className='flex items-center gap-2'>{row.address}
      <button type='button' onClick={() => { window.open(`https://www.google.com/maps?q=${encodeURIComponent(row.address)}`, "_blank") }}>
        <Navigation height="20" width="20" />
      </button>
    </div>,
    width: "32%",
  }
   ]
const Properties = () => {
  const { propertieList } = useSelector(state => state.user)
  return (
    <>
      <DataTableComponent
        columns={columns}
        data={propertieList}
      />
    </>
  )
}

export default Properties
