import { useSelector } from 'react-redux';

import { toast } from 'react-hot-toast';

export const successToast = (message) => {
	toast.success(message, {
		style: {
			background: '#333',
			color: '#fff',
		},
		onClick: () => toast.dismiss(),
	});
};

export const promiseToast = async (loading, message, functionCall) => {
	return await toast.promise(
		functionCall,
		{
			loading: loading || 'Hold on...',
			success: (response) => {
				const { payload } = response;
				if (payload.error) throw payload;
				return message || payload?.message || 'success';
			},
			error: (e) => e.error || 'something went wrong',
		},
		{
			style: {
				background: '#333',
				color: '#fff',
			},
			success: {
			},
			error: {

			}
		}
	);
};

export const errorToast = (message) => {
	let id = toast.error(message, {
		style: {
			background: '#333',
			color: '#fff',
		},
	});
	setTimeout(() => {
		document.addEventListener('click', () => toast.dismiss(id))
	}, 1000);
};


export const isValidName = (name) => {
	const namePattern = /^[a-zA-Z0-9 '-_,.&]+$/;
	return namePattern.test(name);
};


export const customFilter = (data, filters, filterKeys) => {
	return data.filter(item => {
		return filters.every(filter => {
			const { value } = filter;
			return filterKeys.some(filterKey => {
				const filterParts = filterKey.split('.');
				const propertyValue = getProperty(item, filterParts);
				if (propertyValue !== undefined && propertyValue !== null && !!propertyValue) {
					const stringValue = propertyValue?.toString().toLowerCase();
					if (typeof value === 'number' || !isNaN(Number(value))) {
						
						return stringValue.includes(value?.toString());
					} else if (typeof value === 'string') {
						return stringValue.includes(value?.toLowerCase());
					}
				}
				return false;
			});
		});
	});
};

export const getProperty = (obj, keys) => {
	let value = obj;
	for (const key of keys) {
		if (value && value.hasOwnProperty(key)) {
			value = value[key];
		} else {
			value = undefined;
			break;
		}
	}
	return value;
};


const useAccessControl = () => {
	const { modules, user } = useSelector(state => state.auth);

	const checkRoleAccess = (requiredRoles, requiredModules) => {
		if (!Array.isArray(requiredRoles) || !Array.isArray(requiredModules)) {
			console.error('Required roles and modules should be arrays.');
			return false;
		}

		return modules.some(module => {
			return (
				requiredModules.includes(module) &&
				requiredRoles.includes(user?.role)
			);
		});
	};
	return { checkRoleAccess };
};

export default useAccessControl;
