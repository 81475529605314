import React, {  useEffect, useState } from 'react'
import { Reset, TotalTicket } from '../../assets'
import './dashboard.css';
import Progress from './Progress';
import { Calendar} from '../../components/controls';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Dashboard = () => {
  const { ticketList } = useSelector(state => state.ticket);
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')


  const [statusCounts, setStatusCounts] = useState({
    pending: 0,
    ongoing: 0,
    completed: 0,
    closed: 0,
  });

  const [percentageCounts, setPercentageCounts] = useState({
    pending: 0,
    ongoing: 0,
    completed: 0,
    closed: 0,
  });


  const updateData = (list) => {
    const counts = list.reduce((acc, ticket) => {
      const status = ticket.status;
      if (status === 'pending') {
        acc.pending += 1;
      } else if (status === 'ongoing') {
        acc.ongoing += 1;
      } else if (status === 'completed') {
        acc.completed += 1;
      } else if (status === 'closed') {
        acc.closed += 1;
      }
      return acc;
    }, { pending: 0, ongoing: 0, completed: 0, closed: 0 });

    setStatusCounts(counts);

    // Calculate the percentages
    const totalCount = list.length;
    const percentages = {
      pending: (counts.pending / totalCount) * 100,
      ongoing: (counts.ongoing / totalCount) * 100,
      completed: (counts.completed / totalCount) * 100,
      closed: (counts.closed / totalCount) * 100,
    };
    setPercentageCounts(percentages);
  }

  useEffect(() => {
    updateData(ticketList)

  }, [ticketList]);

  const filterTickets = (tickets, params) => {
    return tickets.filter(ticket => {
      let includeTicket = true;

      if (params.startDate && params.endDate) {
        const ticketDate = moment(ticket?.updateStatus_on);

        if (
          !ticketDate.isBetween(params.startDate, params.endDate, null, '[)') &&
          !ticketDate.isSame(params.endDate, 'day')
        ) {
          includeTicket = false;
        }
      }

      return includeTicket;
    });
  };


  useEffect(() => {

    if (startDate && endDate) {
      updateData(filterTickets(ticketList, { startDate, endDate }))
    }

    return () => {
    }
  }, [ticketList, startDate, endDate])

  function addLeadingZero(number) {
    return Math.round(number || 0);
  }
  const handleReset = () => {
    setStartDate('')
    setEndDate('')
    updateData(ticketList)
  }
  return (
    <>
      <div className='subheader flex items-center '>
        <div></div>
        <div className="flex gap-4">
          <Calendar value={startDate} onChange={(e) => setStartDate(e.format())} placeholder="From" id='from' inputClass=' text-sm w-[100px]' />
          <Calendar value={endDate} onChange={(e) => setEndDate(e.format())} placeholder="To" id='to' className='w-[100px]' inputClass='text-sm w-[100px]' />
          <button onClick={handleReset} title="Reset all filters"><Reset height='25' width='25' className='font-[600]' /></button>
        </div>
        
      </div>
      <div className="grid w-full grid-cols-2 gap-5 wrap">
        <div className='flex w-full gap-5'>
          <div className="count-box">
            <TotalTicket colorA={"#34b53a"} colorB={"#e2fbd7"} />
            <span className='font-medium text-[24px]'>{addLeadingZero(statusCounts.closed)}</span>
            <p className="count-label">Total Tickets Closed</p>
          </div>
          <div className="count-box">
            <TotalTicket />
            <span className='font-medium text-[24px]'>{addLeadingZero(statusCounts.completed)}</span>
            <p className="count-label">Total Tickets Resolved</p>
          </div>
        </div>
        <div className='flex w-full gap-5'>
          <div className="count-box">
            <TotalTicket colorA={"#4339f2"} colorB={"#dad7fe"} />
            <span className='font-medium text-[24px]'>{addLeadingZero(statusCounts.ongoing)}</span>
            <p className="count-label">Total Tickets On going</p>
          </div>
          <div className="count-box">
            <TotalTicket colorA={"#ff3a29"} colorB={"#ffe5d3"} />
            <span className='font-medium text-[24px]'>{addLeadingZero(statusCounts.pending)}</span>
            <p className="count-label">Total Tickets Pending</p>
          </div>
        </div>
        <div className="container">
          <div className="count-bars">
            <h2 className='text-[18px] font-[500]'>Tickets Summary</h2>
            <div className="bars-indicate">
              <div className="flex items-center gap-2 indicator green">
                <span>Closed</span>
              </div>
              <div className="flex items-center gap-2 indicator sky">
                <span>Resolved</span>
              </div>
              <div className="flex items-center gap-2 indicator blue">
                <span>Ongoing</span>
              </div>
              <div className="flex items-center gap-2 indicator red">
                <span>Pending</span>
              </div>
            </div>
            <div className="flex items-center gap-10 mt-3 flex-wrap">
              <Progress
                bg="#E2FBD7"
                bar="#34B53A"
                data={addLeadingZero(percentageCounts.closed)}
              />
              <Progress
                bg="#CCF8FE"
                bar="#02A0FC"
                data={addLeadingZero(percentageCounts.completed)}
              />
              <Progress
                bg="#DAD7FE"
                bar="#4339F2"
                data={addLeadingZero(percentageCounts.ongoing)}
              />
              <Progress
                bg="#FFE5D3"
                bar="#FF3A29"
                data={addLeadingZero(percentageCounts.pending)}
              />
            </div>
            <p className='mt-2 text-[12px] w-[80%] text-[#2B2B2B] opacity-[0.4]'>Nunc id leo ornare, aliquam metus ac, congue lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra</p>
          </div>
        </div>
      </div>
      <div className='dashboard h-auto gap-3 w-full mt-2'>
        <div className="count-wrapper flex gap-5">
        </div>
      </div>
    </>
  )
}

export default Dashboard
