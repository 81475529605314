import { useEffect, useState } from "react";
import './message.css'
import Ticket from "./Ticket";
import { ChatIcon } from "../../assets";
import Chat from './Chat'
import moment from 'moment';
import { useSelector } from "react-redux";
const Message = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [list, setList] = useState([])
    const { ticketList } = useSelector(state => state.ticket)
    const { chat1, chat2 } = useSelector(state => state.chat)
    const [defaultClose, setDefaultClose] = useState(false)
    const [defaultClose1, setDefaultClose1] = useState(false)

    useEffect(() => {
        const sortedList = ticketList.filter(a => a.status !== "closed").sort((a, b) => {
            const dateA = moment(a.time_slot);
            const dateB = moment(b.time_slot);

            if (dateA.isBefore(dateB)) {
                return -1;
            } else if (dateA.isAfter(dateB)) {
                return 1;
            } else {
                return 0;
            }
        });
        setList(sortedList);
    }, [ticketList])

    return (
        <>
            <div className="chats fixed  pointer-events-none bottom-0 right-[30%] z-20 flex items-end gap-2">
                {Object.keys(chat2).length !== 0 && <Chat defaultClose={defaultClose} setDefaultClose={setDefaultClose} data={chat2} />}
                {Object.keys(chat1).length !== 0 && <Chat defaultClose={defaultClose1} setDefaultClose={setDefaultClose1} data={chat1} />}
            </div>
            <div className={` rcw-widget-container  ${isOpen ? "" : "rcw-close-widget-container"}`}>
                {
                    <div className={`rcw-conversation-container animate-once animate-liner animate-duration-300   ${isOpen ? "active animate-fade-up " : "inactive animate-jump-out "} `} aria-live="polite">
                        <div id="header" className="rcw-header " >
                            <span>Message</span>
                            <div className="flex gap-4">
                                <div className="toggle" onClick={() => setIsOpen(false)}>X</div>
                            </div>
                        </div>
                        <div className="rcw-messages-container">
                            {
                                list.map(t => <Ticket data={t} key={t.id}
                                    setDefaultClose={setDefaultClose}
                                    setDefaultClose1={setDefaultClose1}


                                />)
                            }
                        </div>
                    </div>}
                <button type="button" className="rcw-launcher animate-once animate-ease-in-out animate-delay-500 animate-fade-up " onClick={() => setIsOpen(!isOpen)}>
                    <ChatIcon height='60' width='60' />
                </button>
            </div>
        </>
    );
};
export default Message