import { useRef, useState } from 'react'
import { Button, ProfileImg } from '../../components/controls'
import DropDown from '../../components/controls/Select'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { errorToast, promiseToast } from '../../utils'
import { getUser, setUsers } from '../../store/user/userActions'
import { View } from '../../assets'

const CreateUser = () => {
  const ref = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([]);
  const initialStates = {
    name: "",
    phone_number: "",
    email: "",
    password: "",
    module: []
  }
  const [userData, setUserData] = useState(initialStates);

  const [imageFile, setImageFile] = useState(false)
  const [imagePreviewSrc, setImagePreviewSrc] = useState('')
  const [isImageSelected, setIsImageSelected] = useState('')


  const showImagePreviewAndValidate = (e) => {
    let selectedFile = ref.current.files.item(0)
    let supportedFormat = ["image/jpeg", "image/png", "image/svg+xml", "image/webp"]
    let size = selectedFile && parseFloat(selectedFile.size / 1024).toFixed(2);
    if (!selectedFile) return setIsImageSelected(false)
    if (!supportedFormat.includes(selectedFile.type)) {
      errorToast("Invalid file type. Only JPEG/PNG/icons are allowed.")
      return setIsImageSelected(false)
    }
    if (size > 5000) {
      e.target.value = ""
      setIsImageSelected(false)
      return errorToast("File size is more then 5 MB")
    }

    setImageFile(ref.current.files[0])
    let fileReader = new FileReader();
    fileReader.readAsDataURL(selectedFile);
    fileReader.addEventListener('load', (event) => {
      setImagePreviewSrc(event.target.result)
      setIsImageSelected(true)
    })
  }

  const clear = () => {
    setUserData(initialStates);
    setSelectedOptions([])
    navigate(-1)
  }
  function isValidName(name) {
    const namePattern = /^[A-Za-z\s]+$/;
    return namePattern.test(name);
  }
  const handleChange = e => setUserData({ ...userData, [e.target.name]: e.target.value })

  const handleSubmit = async e => {
    const { name, phone_number, email, password, module } = userData;
    e.preventDefault();
    if (name === '' || phone_number === '' || email === '' || password === '') {
      return errorToast("All fields are mandantary !")
    }
    else if (!isValidName(name)) {
      return errorToast("Please enter valid name")
    }
    else if (phone_number.length !== 10) {
      return errorToast("Please enter 10 digit contact number")
    }
    else if (password.length <= 6 || password.length === 0) {
      return errorToast("Password length must be greater than 6 characters")
    }
    else if (module.length === 0) {
      return errorToast("Please provide user access")
    }

    let res = await promiseToast("Creating User...", null, dispatch(setUsers({ userData, file: imageFile })))
    if (res.error) return;
    await dispatch(getUser())
    clear();
  }

  return (
    <form className='form-control p-8 flex gap-5 relative' onSubmit={handleSubmit}>

      <input hidden onChange={showImagePreviewAndValidate} ref={ref} data-max-size="500" type="file" accept="image/*" />
      <div className=" mt-5 w-96 flex justify-center cursor-pointer">
        {isImageSelected ?
          <ProfileImg src={imagePreviewSrc} onClick={() => { ref.current.click() }} className={"w-14 h-14 "} />
          :
          <ProfileImg onClick={() => { ref.current.click() }} className={"w-14 h-14 "} />
        }
      </div>

      <div className="control-wrapper" >
        <label htmlFor="name">Name*</label>
        <input type="text" id='name' name='name' className='inputField' required onChange={handleChange} value={userData.name} />
      </div>
      <div className="control-wrapper">
        <label htmlFor="phone_number">Contact No.*</label>
        <input type="number" id='phone_number' name='phone_number' className='inputField' required onChange={handleChange} value={userData.phone_number} />
      </div>
      <div className="control-wrapper">
        <label htmlFor="email">Email ID*</label>
        <input type="email" id='email' name='email' className='inputField' required onChange={handleChange} value={userData.email} />
      </div>

      <div className="control-wrapper">
        <label htmlFor="password">Password*</label>
        <div className="relative flex items-center">
          <input type={showPassword ? "text" : "password"} id='password' name='password' className='border border-[#D1D1D1] rounded-[5px] py-2 w-full px-3 focus:outline-0' required onChange={handleChange} value={userData.password} />
          <button type='button' aria-label="hide_show" onClick={() => setShowPassword(prev => !prev)} className='absolute right-5 flex items-center justify-center '>
            <View width={20} height={20} />
            <div className={`line ${showPassword ? 'hide-line' : ''}`}></div>
          </button>
        </div>
      </div>

      <div className="control-wrapper">
        <label htmlFor="access">Select Access*</label>
        <DropDown setUserData={setUserData} className="z-5" selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />
      </div>
      <div className="buttons absolute bottom-[-75px] left-0 flex h-[50px] w-[300px] gap-5">
        <Button type='submit' classNames="bg-[#016268] h-[50px] w-[150px] text-[#fff] ">Submit</Button>
        <Button type='reset' classNames="bg-[#F1F1F1] h-[50px] w-[150px]" onClick={clear} >Cancel</Button>
      </div>

    </form>
  )
}

export default CreateUser
