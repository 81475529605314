import React, { useRef, useState } from 'react'
import { Button, ProfileImg } from '../../components/controls'
import { EditProf, View } from '../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { logoutUser, updateUsers } from '../../store/auth/authActions'
import Swal from 'sweetalert2';
import { errorToast, promiseToast, successToast } from '../../utils'

const Profile = () => {
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false)

    const [cnfPassword, setCnfPassword] = useState("");
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef(null)

    const [imageFile, setImageFile] = useState(false)
    const [imagePreviewSrc, setImagePreviewSrc] = useState('')
    const [isImageSelected, setIsImageSelected] = useState('')

    const showImagePreviewAndValidate = (e) => {
        let selectedFile = ref.current.files.item(0)
        let supportedFormat = ["image/jpeg", "image/png", "image/svg+xml", "image/webp"]
        let size = selectedFile && parseFloat(selectedFile.size / 1024).toFixed(2);
        if (!selectedFile) return setIsImageSelected(false)
        if (!supportedFormat.includes(selectedFile.type)) {
            errorToast("Invalid file type. Only JPEG/PNG/icons are allowed.")
            return setIsImageSelected(false)
        }
        if (size > 5000) {
            e.target.value = ""
            setIsImageSelected(false)
            return errorToast("File size is more then 5 MB")
        }
        setImageFile(ref.current.files[0])
        let fileReader = new FileReader();
        fileReader.readAsDataURL(selectedFile);
        fileReader.addEventListener('load', (event) => {
            setImagePreviewSrc(event.target.result)
            setIsImageSelected(true)
        })
    }



    const logoutHandler = async () => {
        Swal.fire({
            title: 'Warning!',
            text: "Are you sure, you want to log out?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#016268',
            cancelButtonColor: '#f1f1f1',
            focusCancel: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(logoutUser())
                await successToast("Come back soon! ")
                navigate("/")
                window.location.reload()
            }
        })
    };

    const handleSubmit = async e => {
        e.preventDefault();
        let userProfile = {}
        if (user.password !== password) {
            if (password !== '' || cnfPassword !== '') {

                if (password.length <= 6) {

                    return errorToast("Password length must be greater than 6 characters")
                }
                else if (password !== cnfPassword) {

                    return errorToast("Password miss-match")
                }
                else {
                    userProfile.password = password

                }
            }
        }
        if (isImageSelected || password !== '' || cnfPassword !== '') {
            userProfile.file = imageFile
            let res = await promiseToast("Updating Profile...", null, dispatch(updateUsers({ ...userProfile })))
            if (res.error) return;
            navigate(-1)
        }
        else {
            errorToast("Please provide any change to update profile")
        }


    }
    return (
        <>
            <div className='mb-5 flex items-center gap-5'>
                <figure className=' image-item relative inline-block group cursor-pointer'>
                    <input type="file" id='file' name='file' hidden ref={ref} onChange={showImagePreviewAndValidate} className=' focus:outline-0 w-full ' />
                    {isImageSelected ?
                        <ProfileImg src={imagePreviewSrc} onClick={() => { ref.current.click() }} height={75} width={75} className={"w-20 h-20"} />
                        : <ProfileImg image={user?.profile_image} onClick={() => { ref.current.click() }} height={75} width={75} className={"w-20 h-20"} />
                    }
                    <div className="view-button pointer-events-none rounded-full">
                        <span className="view-text">Edit</span>
                    </div>
                    <span className="edit absolute right-0 top-1 p-1 bg-gray-300 rounded-full h-5 w-5 flex items-center justify-center "><EditProf className="w-3 " /></span>
                </figure>
                <span className='text-2xl'>{user?.username}</span>
            </div>
            <form className='border border-[#D1D1D1] rounded-[10px] w-full p-8 gap-5 relative'>
                <div className='w-full flex gap-5'>
                    <div className="controls text-[14px] flex flex-col gap-3 w-full">
                        <label htmlFor="name">Name</label>
                        <input type="text" name='name' id='name' value={user.username} disabled className='border border-[#D1D1D1] rounded-[5px] py-2 px-3 focus:outline-0 cursor-no-drop' />
                    </div>
                    <div className="controls text-[14px] flex flex-col gap-3 w-full">
                        <label htmlFor="contact">Contact No.</label>
                        <input type="text" name='contact' value={user.phone_number} id='contact' disabled className='border border-[#D1D1D1] rounded-[5px] py-2 px-3 focus:outline-0 cursor-no-drop w-full' />
                    </div>
                    <div className="controls text-[14px] flex flex-col gap-3 w-full">
                        <label htmlFor="email">Email ID</label>
                        <input type="email" name='email' value={user.email} id='email' disabled className='border border-[#D1D1D1] rounded-[5px] py-2 px-3 focus:outline-0 cursor-no-drop' />
                    </div>
                </div>
                <div className='w-full flex gap-5 mt-5'>

                    <div className="controls text-[14px] flex flex-col gap-3 w-full">
                        <label htmlFor="email">Change Password</label>
                        <input type="password" name='password' value={password} onChange={e => setPassword(e.target.value)} id='changePassword' placeholder='Enter new password' className='border border-[#D1D1D1] rounded-[5px] py-2 px-3 focus:outline-0' />
                    </div>

                    <div className="controls text-[14px] flex flex-col gap-3 w-full">
                        <label htmlFor="cnfPassword">Confirm Password</label>
                        <div className="relative flex items-center">
                            <input type={showPassword ? "text" : "password"} name='cnfPassword' value={cnfPassword} onChange={e => setCnfPassword(e.target.value)} id='cnfPassword' placeholder='Confirm password' className='border border-[#D1D1D1] rounded-[5px] py-2 w-full px-3 focus:outline-0' />
                            <button type='button' aria-label="hide_show" onClick={() => setShowPassword(prev => !prev)} className='absolute right-5 flex items-center justify-center '>
                                <View width={20} height={20} />
                                <div className={`line ${showPassword ? 'hide-line' : ''}`}></div>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="buttons absolute bottom-[-75px] left-0 flex h-[50px] w-[300px] gap-5">
                    <Button classNames="bg-[#016268] h-[42px] w-[150px] text-[#fff] btn-save" onClick={handleSubmit} >Save Changes</Button>
                    <Button type='button' className="bg-[#F1F1F1] h-[42px] text-[14px] rounded-[5px] font-[500] w-[150px] cancel-btn" onClick={logoutHandler} >Log Out</Button>
                </div>

            </form>
        </>
    )
}

export default Profile
