import { createSlice } from '@reduxjs/toolkit';
import { deleteCategory, getCategories, setCategories, updateCategories } from './categoryAction';

const initialState = {
    loading: false,
    categoryList: [],
    categoryById: null,
    error: null
};

const userSlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken;
            localStorage.setItem('accessToken', action.payload.accessToken);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categoryList = action.payload.data;
                state.error = null;
            })
            .addCase(getCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
            .addCase(setCategories.pending, (state) => {
                state.loading = true;
            })
            .addCase(setCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categoryList = [...state.categoryList, { ...action.payload.data, icon: JSON.stringify(action.payload.data.icon) }];
                state.error = null;
            })
            .addCase(setCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
            .addCase(updateCategories.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCategories.fulfilled, (state, { payload }) => {
                state.loading = false;
                const updatedCategory = payload.data;
                const updatedList = state.categoryList.map(category => {
                    if (category.id === updatedCategory.id) {
                        return {
                            ...updatedCategory,
                            icon: typeof updatedCategory.icon === 'string' ? updatedCategory.icon : JSON.stringify(updatedCategory.icon)
                        };

                    }
                    return category;
                });
                state.categoryList = updatedList;
                state.error = null;
            })
            .addCase(updateCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
            .addCase(deleteCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCategory.fulfilled, (state, { payload }) => {
                state.loading = false;
                const id = payload.id;
                state.categoryList = state.categoryList.filter(e => e.id !== parseInt(id));
                state.error = null;
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
    },
});

export default userSlice.reducer;

export const { updateUser } = userSlice.actions;
