import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorToast } from '../../utils';
import axiosConfig from '../../utils/axiosConfig';


export const loginUser = createAsyncThunk('user/login', async ({
	email, password
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('auth/login/user', {
			email,
			password
		});
		if (response.success === 0) return errorToast(`${response.message} failed`)
		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.error.message
		});
	}
});

export const verifyUserDetails = createAsyncThunk('user/verify', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('auth/verify/user');
		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const updateUsers = createAsyncThunk('users/update/profile', async ({ password, file }, { rejectWithValue }) => {
	try {
		let formData = new FormData();

		if (file) {
			formData.append('file', file);
			password && formData.append('password', password)
		} else {
			formData = { password }
		}
		const response = await axiosConfig.patch(`auth/update/profile/user`, formData);
		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});


export const logoutUser = createAsyncThunk('user/logout', async (_, { rejectWithValue }) => {
	try {

		return true;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const resetPassword = createAsyncThunk('user/resetPassword', async ({ token, password, confirmPassword }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('auth/reset-password/user', {
			token, password, confirmPassword
		});
		if (response.success === 0) return errorToast(`${response.message} failed`)
		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.error.message
		});
	}
});

export const forgotPassword = createAsyncThunk('user/forgotPassword', async ({ email }, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('auth/forgot-password/user', { email });
		if (response.success === 0) return errorToast(`${response.message} failed`)
		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.error.message
		});
	}
});