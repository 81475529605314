import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import store from './store';
import App from './app/app';
import { DataProvider } from './context/DataProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<DataProvider >
			<App />
		</DataProvider>
	</Provider>
);

// reportWebVitals();
serviceWorkerRegistration.unregister();