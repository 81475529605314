import React, { useState } from 'react';
import Select from 'react-select';


const Filter = ({options, onChange, name, inputRef, ...props }) => {

    const [selectedOptions, setSelectedOptions] = useState('')
    const customStyles = {  
        option: (provided, state) => ({
            ...provided,
            fontWeight: '500',
            fontSize:'13px',
            backgroundColor: state.isSelected ? '#016268' : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:active': {
                      background: '#016268',
                      color:"#fff"
                  },
                  '&:hover':{
                    background: '#016268',
                    color:"#fff",
                    cursor:'pointer'
                  }
          }),        
          singleValue: (provided) => ({
            ...provided,
            fontWeight: '500',
          }),
    };
  
    return (
        <Select
            options={options?.map((option) => ({
                ...option,
            }))}
            className='bg-transparent w-36 focus:outline-0 z-10 pointer'
            selected={selectedOptions || ''}
            components={{
                IndicatorSeparator: () => null
            }}
            ref={inputRef}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            menuClassName={'custom-menu-styles'}
            onChange={(e) => onChange(name, e?.value)}
            isClearable={false}
            styles={customStyles}
            defaultValue={options && options[0]}
            {...props}
            isSearchable={false}
        />
    );
};

export default Filter;
