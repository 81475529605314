import { useCallback, useState } from "react";
import './chat.css'
import { Reset, Send } from "../../assets";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import ScrollToBottom from "react-scroll-to-bottom";

import { closeChat, initChat, sendMessage } from "../../store/chat/chatActions";

const Chat = ({ data, defaultClose }) => {
    const [isOpen, setIsOpen] = useState(defaultClose)
    const auth = useSelector(state => state.auth)
    const [msg, setMsg] = useState('')
    const dispatch = useDispatch()
    const { ticket_id, messages } = data;

    const handleRefresh = async e => {
        e.stopPropagation();
        await dispatch(initChat(ticket_id))
    }
    const handleClose = async e => {
        e.stopPropagation();
        await dispatch(closeChat(data.id))
    }

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!msg) return;
        const newMessage = {
            chat_id: data.id,
            message: msg,
            sender_id: auth?.user?.id,
            createdAt: new Date().toISOString(),
            entity_type: "user",
        };
        setMsg('');
        await dispatch(sendMessage(newMessage))
        setMsg('');
    }, [msg, data.id, auth?.user?.id, dispatch]);

    return (
        <>
            <div className={`chat-wrapper ${isOpen ? 'open bottom-4 shadow ' : 'close bottom-0 '} pointer-events-auto `}>
                <div id="header-toggle" className="toggle-menu" onClick={() => setIsOpen(!isOpen)} >
                    <div>Ticket No. {ticket_id}</div>
                    {isOpen && (
                        <div className="flex gap-4 items-center justify-center ">
                            <div className="refresh  flex items-center p-1 hover:underline" onClick={handleRefresh}><Reset color={'white'} height="20" width="20" /></div>
                            <button type="button" className="toggle flex items-center p-1 hover:underline" onClick={handleClose}>Close</button>
                        </div>
                    )}
                </div>
                <div className={`${isOpen ? 'open' : 'close'} chat-body rounded-lg`} >
                    <ScrollToBottom
                        scrollViewClassName="chat-list"
                        className="chat-container"
                        useAnimatingToEnd={true}
                    >
                        {messages?.map(({ sender_id, entity_type, user, message, createdAt }, i) => {
                            let isMe = sender_id === auth?.user?.id && entity_type === "user"
                            return (
                                <div key={i} className={`chat ${isMe ? 'self-end' : 'self-start'} text-[12px] font-medium mt-1`}>
                                    <p className="font-[600] text-[12px] text-left">{!isMe && user?.username}</p>
                                    <p className="">{message}</p>
                                    <p className="text-right text-[10px]">{moment(createdAt).format('hh:mm a')}</p>
                                </div>)
                        })}
                    </ScrollToBottom>
                    <form className="input" onSubmit={handleSubmit}>
                        <input type="text" name="message" onChange={e => setMsg(e.target.value)} autoComplete="off" value={msg} placeholder="Type message here..." className="w-full py-2 rounded-full px-4" />
                        <button type="submit">
                            <Send height="25" width="25" className="send -rotate-[30deg] -mt-2" />
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};
export default Chat