import React from 'react'
import { Delete, Edit, View } from '../../assets'
import { Link } from 'react-router-dom'
import { promiseToast } from '../../utils'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'

const ActionButton = ({ row, canDelete, deleteCall, hideSee, canEdit }) => {
  const dispatch = useDispatch()
  const handleDelete = e => {
    Swal.fire({
      title: 'Warning!',
      text: "Are you sure, you want to delete?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#016268',
      cancelButtonColor: '#f1f1f1',
      focusCancel: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        promiseToast("Deleting", null, dispatch(deleteCall))
      }
    })

  }
  return (
    <div className='flex items-center gap-4 justify-center w-[80%]'>

      {!canEdit && <Link to={`update/${row.id}`} aria-label="edit">
        <Edit height="20" width="20" />
      </Link>}
      {!hideSee && <Link to={`view/${row.id}`} aria-label="view">
        <View height="20" width="20" />
      </Link>}

      {canDelete &&
        <button type="button" onClick={handleDelete}>
          <Delete height="20" width="20" />
        </button>
      }
    </div>
  )
}

export default ActionButton