import DatePicker from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import './controls.css'
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import { Calendar as CalendarIcon } from '../../assets';
const Calendar = ({ showTime, ...props }) => {
    return (
        <div className="search date flex gap-2 justify-between items-center pointer ">
            <DatePicker
                weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                plugins={showTime && [
                    <TimePicker hideSeconds />
                ]}
                
                containerStyle={{ width: "100%" }}
                editable={false}
                {...props} />
            <label htmlFor={props.id} ><CalendarIcon htmlFor="datepicker1" height="20" width="20"  />
            </label>

        </div>
    )
}


export default Calendar
