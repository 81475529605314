import React, { useState,useEffect } from 'react'
import { Button } from '../../components/controls'
import { useDispatch, useSelector } from 'react-redux'
import { errorToast, promiseToast } from '../../utils';
import { updateNotifications } from '../../store/notification/notificationAction';
import { useNavigate, useParams } from 'react-router-dom';


const EditNotification = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { notificationList } = useSelector(state => state.notification)
    const data = notificationList.find(c => c.id === parseInt(id))
    const [notification, setNotification] = useState("");

    useEffect(() => {
        setNotification(data)
    }, [notificationList, id, data])

    const handleChange = e => {
        const { name, value } = e.target;
        setNotification({
            ...notification,
            [name]: value
        })
    }

    const clear = () => {
        setNotification("")
        navigate(-1)
    }
    function isValidName(name) {
        const namePattern = /^[A-Za-z\s]+$/;
        return namePattern.test(name);
      }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {name,phone_number,email,designation,}=notification;
    if (name === ''|| phone_number === ''|| email === ''|| designation === '') {
      return errorToast("All fields are mandantary !")
    }
    else if(!isValidName(name)){
      return errorToast("Please enter valid name")
    }
    else if(!isValidName(designation)){
      return errorToast("Please enter valid designation")
    }
    else if(phone_number.length !== 10){
      return errorToast("Please enter 10 digit contact number")
    }
    else if (module.length===0) {
      return errorToast("Please provide user access")
    }  
        let res = await promiseToast("Creating Notification...", null, dispatch(updateNotifications({ id, notification })))
        if (res.error) return;
        clear();
    }
    return (
        <form className='form-control flex gap-5 relative p-8' onSubmit={handleSubmit}>
            <div className="control-wrapper" >
                <label htmlFor="name">Name*</label>
                <input type="text" id='name' name="name" className='inputField' required value={notification?.name || " "} onChange={handleChange} />
            </div>
            <div className="control-wrapper">
                <label htmlFor="designation">Designation*</label>
                <input type="text" id='designation' name="designation" className='inputField' required value={notification?.designation || " "} onChange={handleChange} />
            </div>
            <div className="control-wrapper">
                <label htmlFor="phone_number">Contact No.*</label>
                <input type="number" id='phone_number' name="phone_number" className='inputField' required value={notification?.phone_number || " "} onChange={handleChange} />
            </div>
            <div className="control-wrapper">
                <label htmlFor="email">Email ID*</label>
                <input type="email" id='email' name="email" className='inputField' required value={notification?.email || " "} onChange={handleChange} />
            </div>
            <div className="buttons absolute bottom-[-75px] left-0 flex h-[50px] w-[300px] gap-5">
                <Button type="Submit" classNames="bg-[#016268] h-[50px] w-[150px] text-[#fff] ">Submit</Button>
                <Button type="reset" classNames="bg-[#F1F1F1] h-[50px] w-[150px]" onClick={clear} >Cancel</Button>
            </div>

        </form>
    )
}

export default EditNotification
