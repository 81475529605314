import { Button, ProfileImg } from '../../components/controls'
import { Upload } from '../../assets'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCategories } from '../../store/category/categoryAction'
import { errorToast, promiseToast } from '../../utils'
import { useNavigate, useParams } from 'react-router-dom';


const EditCategories = () => {
  const ref = useRef(null)
  const { id } = useParams()
  const { categoryList } = useSelector(state => state.category)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [name, setName] = useState(" ");
  let category = categoryList.find(c => c.id === parseInt(id))

  const [imageFile, setImageFile] = useState(false)
  const [imagePreviewSrc, setImagePreviewSrc] = useState('')
  const [isImageSelected, setIsImageSelected] = useState('')
  const [imagePath, setImagePath] = useState("")

  useEffect(() => {
    setName(() => category?.name)
    setImagePath(category?.icon)
  }, [categoryList, id, category?.icon, category?.name])


  const showImagePreviewAndValidate = (e) => {
    let selectedFile = ref.current.files.item(0)
    let supportedFormat = ["image/jpeg", "image/png", "image/svg+xml", "image/webp"]
    let size = selectedFile && parseFloat(selectedFile.size / 1024).toFixed(2);
    if (!selectedFile) return setIsImageSelected(false)
    if (!supportedFormat.includes(selectedFile.type)) {
      errorToast("Invalid file type. Only JPEG/PNG/icons are allowed.")
      return setIsImageSelected(false)
    }
    if (size > 5000) {
      e.target.value = ""
      setIsImageSelected(false)
      return errorToast("File size is more then 5 MB")
    }
    
    setImageFile(ref.current.files[0])
    let fileReader = new FileReader();
    fileReader.readAsDataURL(selectedFile);
    fileReader.addEventListener('load', (event) => {
      setImagePreviewSrc(event.target.result)
      setIsImageSelected(true)
    })
  }

  const clear = () => {
    setName("")
    navigate(-1)
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateData = {}
    if(name === category?.name && !isImageSelected){
      return errorToast("No changes have been made.")
    }
    if (name) updateData.name = name
    if (imageFile) updateData.icon = imageFile

    let res = await promiseToast("Updating Category...", null, dispatch(updateCategories({ id, name, icon: imageFile })))
    if (res.error) return;
    clear();
  }
  return (
    <form className='form-control p-8 flex gap-5 relative align-middle ' onSubmit={handleSubmit}>
      <div className="controls text-[14px] flex flex-col gap-3 w-full">
        <label htmlFor="name">Enter Category Name*</label>
        <input type="text" id='name' name='name' value={name || ""} pattern="^[A-Za-z ,'\-&]+$" onChange={e => setName(e.target.value)} placeholder={"Enter the category name"} className='border border-[#D1D1D1] rounded-[5px] py-2 px-3 focus:outline-0' />
        <div className="mt-1 text-xs text-gray-600">
          Please enter a name using letters (A-Z, a-z), spaces, (,), ('), (-), and (&amp;).
        </div>
      </div>

      <div className="flex justify-center items-center w-20">
        {isImageSelected ?
          <ProfileImg src={imagePreviewSrc} />
          : imagePath &&
          <ProfileImg image={imagePath} />
        }
      </div>


      <div className="controls text-[14px] flex flex-col gap-3 w-full">
        <label htmlFor="file">Upload Image/Icon*</label>
        <button type='button' onClick={() => ref.current.click()} className='upload border border-[#D1D1D1] rounded-[5px] py-2 px-3 flex gap-2'>
          <Upload height="20" width="20" />
          <span className=' text-muted '>
            Upload icon hear
          </span>
        </button>

        <input type="file" id='file' accept="image/*" name='file' hidden ref={ref} onChange={showImagePreviewAndValidate} className=' focus:outline-0 w-full ' />
      </div>


      <div className="buttons absolute bottom-[-75px] left-0 flex h-[50px] w-[300px] gap-5">
        <Button type='submit' classNames="bg-[#016268] h-[50px] w-[150px] text-[#fff] ">Submit</Button>
        <Button type="reset" classNames="bg-[#F1F1F1] h-[50px] w-[150px]" onClick={clear} >Cancel</Button>
      </div>

    </form>
  )
}

export default EditCategories;
