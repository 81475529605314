
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';


export const getCategories = createAsyncThunk('category/get', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get('category/getList');
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const setCategories = createAsyncThunk('category/set', async ({ name, icon }, { rejectWithValue }) => {
    try {
        let formData = new FormData();
        formData.append('name', name);
        formData.append('file', icon);
        const response = await axiosConfig.post('category/create', formData);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const updateCategories = createAsyncThunk('category/update', async ({ id, name, icon }, { rejectWithValue }) => {
    try {

        let formData = new FormData();
        if (icon) {

            formData.append('name', name);
            formData.append('file', icon);
        } else {
            formData = { name }
        }
        const response = await axiosConfig.patch(`category/update/${id}`, formData);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});


export const deleteCategory = createAsyncThunk('category/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.delete(`category/delete/${id}`);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});