import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router'
import { ProfileImg } from '../../components/controls';
import ImagePreview from './modal/ImagePreview';

const TicketDetails = () => {
    const { id } = useParams();
    const { ticketList } = useSelector(state => state.ticket)
    const [data, setData] = useState({})
    useEffect(() => {
        setData(ticketList.find(c => c.id === parseInt(id)))
    }, [ticketList, id])
    const [modal, setModal] = useState(false);
    const [images, setImages] = useState([]);
    const [slideToOpen, setSlideToOpen] = useState(0);
    const toggleModal = (e, index) => {
        setImages(e)
        setModal((modal) => !modal);
        setSlideToOpen(index)
    }

    if (modal) {
        document.body.classList.add("modal-active")
    }
    else {
        document.body.classList.remove("modal-active")

    }
    return (
        <div className='details-wrapper flex gap-5'>
            <div className="details border border-[#D1D1D1] rounded-[10px] w-full h-full">
                <div className="details-box border-b border-[#D1D1D1]">
                    <div className="px-5 py-3">
                        <h2 className="text-[18px] font-[500] ">
                            Property Name
                        </h2>
                        <span className='text-[14px]'>M22 Investments</span>
                    </div>
                </div>
                <div className="details-box border-b border-[#D1D1D1]">
                    <div className="px-5 py-3">
                        <h2 className="text-[18px] font-[500]">
                            Contact No.
                        </h2>
                        <span className='text-[14px]'>{data?.phone_number}</span>
                    </div>
                </div>
                <div className="details-box border-b border-[#D1D1D1]">
                    <div className="px-5 py-3">
                        <h2 className="text-[18px] font-[500]">
                            Category
                        </h2>
                        <span className='text-[14px]'>{data?.category?.name}</span>
                    </div>
                </div>
                <div className="details-box border-b border-[#D1D1D1] h-auto">
                    <div className="px-5 py-3">
                        <h2 className="text-[18px] font-[500]">
                            Fault Details
                        </h2>
                        <span className='text-[14px]'>{data?.fault_details}</span>
                    </div>
                </div>
                <div className="details-box border-b border-[#D1D1D1]">
                    <div className="px-5 py-3">
                        <h2 className="text-[18px] font-[500]">
                            Address
                        </h2>
                        <span className='text-[14px]'>{data?.address}</span>
                    </div>
                </div>
                <div className="details-box border-b border-[#D1D1D1]">
                    <div className="px-5 py-3">
                        <h2 className="text-[18px] font-[500]">
                            Date & Time
                        </h2>
                        <span className='text-[14px]'>
                            {data && moment(data.time_slot).format('DD/MM/YYYY | h:mmA')}
                        </span>
                    </div>
                </div>

                <div className="details-box">
                    <div className="px-5 py-3">
                        <div className="image-group">
                            <div>
                                <h3 className='text-[18px] font-[500]'>Before Images</h3>
                                <div className="image-row">
                                    {data?.image_before && JSON.parse(data?.image_before).map((image, index) => {
                                        return (
                                            <button type='button' key={index} className="image-item m-1 relative object-cover  w-32 h-24  " onClick={() => toggleModal(data.image_before, index)} >
                                                <ProfileImg image={JSON.stringify(image)} defaultClass className=' object-cover w-full h-full' />
                                                <div className="view-button pointer-events-none">
                                                    <span className="view-text">View</span>
                                                </div>
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                            {data?.image_after && <div>
                                <h3 className='text-[18px] font-[500]'>After Images</h3>
                                <div className="image-row">
                                    {JSON.parse(data.image_after).map((image, index) => {
                                        return (<button type='button' key={index} className="image-item m-1 relative object-cover w-32 h-24 " onClick={() => toggleModal(data.image_after, index)}>
                                            <ProfileImg image={JSON.stringify(image)} defaultClass className=' object-cover w-full h-full' />
                                            <div className="view-button pointer-events-none ">
                                                <span className="view-text">View</span>
                                            </div>
                                        </button>)
                                    })}
                                </div>
                            </div>}
                            {modal && (<div className="modal z-30">
                                <div className="overlay " onClick={toggleModal}></div>
                                <ImagePreview images={images} slideToOpen={slideToOpen} />
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="manage w-[400px] bg-[#D1D1D1] rounded-[10px] p-4 flex items-center justify-center">
                Manage ticket
            </div>
        </div>
    )
}

export default TicketDetails
