import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { initChat } from '../../store/chat/chatActions';

const Ticket = ({ data,
  setDefaultClose,
  setDefaultClose1
}) => {
  const { time_slot, category, id } = data;
  const { chat1, chat2 } = useSelector(state => state.chat)

  const dispatch = useDispatch()
  const handleClick = e => {
    dispatch(initChat(e))
  }
  return (
    <div onClick={async () => {
      await handleClick(id)

      if (Object.keys(chat1).length === 0 || chat1?.ticket_id === id) {
        setDefaultClose1(true)
      }
      else if (Object.keys(chat2).length === 0 || chat2?.ticket_id === id) {
        setDefaultClose(true)
      }

    }}>
      <div className="ticket bg-white w-full h-10 flex items-center gap-2 px-4 border-b cursor-pointer hover:bg-[#f1f1f1]">
        <div className=" w-fit p-1 text-[14px] font-medium">
          {id}
        </div>
        <div className="category w-full text-[12px] flex-1 font-medium">
          {category?.name}
        </div>
        <div className="time w-fit flex flex-col items-end">
          <p>{moment(time_slot).format('DD MMM YY')}</p>
          <p >{moment(time_slot).format('hh:mm A')}</p>
        </div>
      </div>
    </div>
  )
}

export default Ticket
