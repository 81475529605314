import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DataContext } from './CreateContex'
import useAccessControl from '../utils'
import { getProperties, getUser, getVendor } from '../store/user/userActions'
import { getCategories } from '../store/category/categoryAction'
import { getNotifications } from '../store/notification/notificationAction'
import { getTickets } from '../store/ticket/ticketAction'
import { initChat } from '../store/chat/chatActions'

const DataProvider = ({ children }) => {
    const dispatch = useDispatch()
    const { checkRoleAccess } = useAccessControl();
    useEffect(() => {
        const getList = () => {
            if (checkRoleAccess(["super_admin", "admin"], ["properties"])) dispatch(getProperties())
            if (checkRoleAccess(["super_admin", "admin", "manager", "vendor"], ["tickets"])) dispatch(getTickets())
            if (checkRoleAccess(["super_admin"], ["users"])) dispatch(getUser())
            if (checkRoleAccess(["super_admin", "admin"], ["vendors"])) dispatch(getVendor())
            if (checkRoleAccess(["manager"], ["notifications"])) dispatch(getNotifications())
            if (checkRoleAccess(["super_admin", "admin", "manager"], ["categories", "tickets"])) dispatch(getCategories())

            if (localStorage.getItem('chat1')) {
                if (checkRoleAccess(["super_admin", "admin", "manager", "vendor"], ["dashboard"])) dispatch(initChat(localStorage.getItem('chat1')))
            }
        if (localStorage.getItem('chat2')) {
                if (checkRoleAccess(["super_admin", "admin", "manager", "vendor"], ["dashboard"])) dispatch(initChat(localStorage.getItem('chat2')))
            }

        }


        return getList()
    }, [checkRoleAccess, dispatch])
    
    return (
        <DataContext.Provider value={{}}>
            {children}
        </DataContext.Provider>
    )

}

const useData = () => {
    const data = useContext(DataContext)
    return data
}


export { DataProvider, useData }






















