import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';


export const getUser = createAsyncThunk('user/get', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get('user/getList');
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const getVendor = createAsyncThunk('vendor/get', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get('user/vendor/getList');
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const getProperties = createAsyncThunk('properties/get', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get('propertie/getList');
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});
export const setUsers = createAsyncThunk('users/set', async ({ userData, file }, { rejectWithValue }) => {
    try {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('username', userData.name);
        formData.append('email', userData.email);
        formData.append('phone_number', userData.phone_number);
        formData.append('password', userData.password);
        formData.append('role', 'admin');
        formData.append('prefix', 'admin');
        formData.append('module', `[${userData.module}]`);
        const response = await axiosConfig.post('user/create', formData);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});
export const updateUsers = createAsyncThunk('users/update', async ({ id, userData, file }, { rejectWithValue }) => {
    try {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('username', userData.username);
        formData.append('phone_number', userData.phone_number);
        formData.append('email', userData.email);
        formData.append('module', userData.module);
        const response = await axiosConfig.patch(`user/update/${id}`, formData);

        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const updateVendors = createAsyncThunk('vendor/update', async ({ id, userData, file }, { rejectWithValue }) => {
    try {

        let formData = new FormData();
        formData.append('file', file);
        formData.append('username', userData.username);
        formData.append('phone_number', userData.phone_number);
        formData.append('email', userData.email);
        formData.append('module', userData.module);
        formData.append('category', userData.category);
        const response = await axiosConfig.patch(`user/update/vendor/${id}`, formData);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});