import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImagePreview = ({ data, images, slideToOpen }) => {

  const settings = {
    slidesToScroll: 1,
    initialSlide: slideToOpen || 0,
  };


  return (
    <Slider  {...settings} className='w-[800px] h-[400px] rounded-[5px] overflow-hidden image-carousel cursor-grab active:cursor-grabbing z-50 outline-none active:outline-none border-none px-[50px]'>
      {JSON.parse(images).map(({ path }, index) => (
        <div key={index} className="h-full w-full bg-cover bg-center" >
          <img src={`${process.env.REACT_APP_URL}/${path}`} alt="image_showcase" className='object-contain w-full h-full flex' />
        </div>
      ))}
    </Slider>
  );
}
export default ImagePreview