import React from 'react';

const Menu = ({ colorA, colorB }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" className="h-[35px] w-[35px]" >
            <circle fill={colorB || '#ccf8fe'} cx="24" cy="24" r="24" />
            <g transform="translate(16 13)">
                <path fill={colorA || '#02a0fc'} d="M14.254,0H7.329A4.334,4.334,0,0,0,3,4.329V19.912a.866.866,0,0,0,1.354.714L6.177,19.38,8,20.626a.866.866,0,0,0,.978,0L10.8,19.38l1.818,1.246a.866.866,0,0,0,.979,0l1.818-1.245,1.818,1.244a.866.866,0,0,0,1.353-.713V4.329A4.334,4.334,0,0,0,14.254,0Zm2.6,18.267-.952-.651a.866.866,0,0,0-.98,0L13.1,18.862l-1.818-1.246a.866.866,0,0,0-.979,0L8.486,18.862,6.668,17.616a.866.866,0,0,0-.977,0l-.959.651V4.329a2.6,2.6,0,0,1,2.6-2.6h6.926a2.6,2.6,0,0,1,2.6,2.6Z" transform="translate(-3)" />
                <rect fill={colorA || '#02a0fc'} width="8" height="1" rx="0.5" transform="translate(3.792 7.389)" />
                <rect fill={colorA || '#02a0fc'} width="7" height="2" rx="1" transform="translate(3.792 10.389)" />
            </g>
        </svg>
    );
};

export default Menu;
