import React, { useCallback, useEffect, useRef, useState } from 'react';
import './controls.css';
import { promiseToast } from '../../utils';
import { useDispatch } from 'react-redux';
import { updateStatus } from '../../store/ticket/ticketAction';
import { Menu as MenuIcon } from '../../assets';

const Dropdown = ({ row }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dispatch = useDispatch()
    const dropdownRef = useRef(null);



    const clickTimeoutRef = useRef(null);

    const handleToggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
        if (!isDropdownOpen) {
            clickTimeoutRef.current = setTimeout(() => {
                document.addEventListener('click', handleOutsideClick);
            }, 100); 
        }
    };

    const handleClosed = async () => {
        await promiseToast("Closing ticket...", null, dispatch(updateStatus({ id: row.id })))
        clearTimeout(clickTimeoutRef.current);
        setIsDropdownOpen(false);
    };

    const handleOutsideClick = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            clearTimeout(clickTimeoutRef.current);
            document.removeEventListener('click', handleOutsideClick);
        }
    }, [])

    useEffect(() => {
        return () => {
            clearTimeout(clickTimeoutRef.current);
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick]);



    return (
        <div>
            <div className="relative inline-block text-left">
                <div>
                    <button
                        onClick={handleToggleDropdown}
                        type="button"
                        className="inline-flex justify-center items-center p-2 text-gray-600 hover:text-gray-900"
                    >
                        <MenuIcon />
                    </button>
                </div>

                <div
                    ref={dropdownRef}
                    className={`dropdown-menu overflow-hidden ${isDropdownOpen ? 'active' : ''}`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    <div className="w-full ">
                        <button
                            type="button"
                            onBlur={handleToggleDropdown}
                            disabled={row?.status === 'closed'}
                            onClick={handleClosed}
                            className="w-full flex px-4 py-3 text-sm text-gray-700  hover:bg-[#016268] hover:text-white"
                            role="menuitem"
                        >
                            Closed
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dropdown;
