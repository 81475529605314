import { useMemo, useState } from "react";
import { ActionButton, Button } from "../../components/controls";
import { Plus } from "../../assets";
import DataTableComponent from "../../components/datatable/DataTableComponent";
import { Link } from "react-router-dom";
import { customFilter } from "../../utils";
import { useSelector } from "react-redux";
import { deleteNotification } from "../../store/notification/notificationAction";


const columns = [
  {
    name: "ID",
    id:'id',
    selector: (row) => row.id,
    width: "10%",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    width: "20%",
  },
  {
    name: "Designation",
    selector: (row) => row.designation,
    width: "20%",

  },
  {
    name: "Contact No",
    selector: (row) => row.phone_number,
    width: "21%",
  },
  {
    name: "Email ID",
    selector: (row) => row.email,
    width: "20%",
  },
  {
    name: "Action",
    width: "9%",
    cell: (row) => <ActionButton row={row} path={`notifications`} canDelete deleteCall={deleteNotification(row.id)} hideSee />
  },
]

const Notifications = () => {
  const [filterText] = useState('');
  const { notificationList } = useSelector(state => state.notification)

  const filteredItems = useMemo(() => {
    return customFilter(notificationList, [{ id: 'global-filter', value: filterText }], ['id', 'username', 'role', 'phone_number', 'email']);
  }, [filterText, notificationList]);

  const subHeaderComponentMemo = useMemo(() => {

    return (
      <div className='subheader'>
        {/* <Search onFilter={(e) => setFilterText(e.target.value)} filterText={filterText} placeholder="Search Notifications"  /> */}
        <div></div>
        <div className="flex gap-4">
          <Link to="/notifications/create" className="flex">
            <Button classNames="bg-[#016268] h-full w-full text-[#fff] ">Add New <Plus className="fill-white" /></Button>
          </Link>
        </div>
      </div>

    );
  }, []);


  return (
    <>
      <DataTableComponent
        columns={columns}
        data={filteredItems}
        subHeaderComponent={
          subHeaderComponentMemo
        }
        pagination
        defaultSortAsc={false}
        defaultSortFieldId={'id'}
      />
    </>
  )
}

export default Notifications
