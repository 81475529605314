import { useState } from 'react';
import { Logo } from '../../assets';
import './forgot.css'
import { useDispatch} from 'react-redux';
import { errorToast, promiseToast } from '../../utils';
import { resetPassword } from '../../store/auth/authActions';
import { useNavigate, useParams } from 'react-router-dom';

const ChangePassword = () => {
    const [password, setPassword] = useState('')
    const [cnfPassword, setCnfPassword] = useState('')
    const dispatch = useDispatch()
    const { token } = useParams()
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        let userProfile = {}
            if (password==='' || cnfPassword === '') {
                userProfile.password = password
                return errorToast("Password cannot be black, Please enter both password fields")
            }
            else if (password.length <= 6 || password.length === 0) {
                userProfile.password = password
                return errorToast("Password length must be greater than 6 characters")
            }
            else if (password !== cnfPassword) {
                return errorToast("Password miss-match")
            }
            else {
                userProfile.password = password
            }

        let res = await promiseToast("Validating...", null, dispatch(resetPassword({ token, password, confirmPassword: cnfPassword })))
        if (res.error) return;
        navigate('/')
        window.location.reload()
    };
    return (
        <section className="bg-gray-50 dark:bg-gray-900 ">
            <div className="flex flex-col items-center bg-[#f1f1f1] justify-center px-6 py-4 mx-auto md:h-screen lg:py-0">

                <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 relative ">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <div className="logo absolute top-[-100px] left-[50%] translate-x-[-50%] ">
                            <Logo className="w-[200px] h-[75px] sidebar-logo " />
                        </div>
                        <div className="heading">
                            <h1 className="text-xl font-[500] leading-tight tracking-tight text-gray-900 md:text-3xl  text-center">
                                Change Your Password
                            </h1>

                        </div>

                        <form className="space-y-4 md:space-y-4" onSubmit={handleSubmit}>
                            <div className="otp-wrapper">
                                <div className={` flex flex-col gap-4 my-3`}>
                                    <input type="password" name='password'
                                        value={password.new}
                                        placeholder="Enter new password"
                                        onChange={e => setPassword(e.target.value)}
                                        id='n_password' className=' bg-[#F7F7F7] rounded-[5px] p-3 focus:outline-0 font-[500] text-[12px] text-[#2B2B2B]' />

                                    <input type="password" name='cnfpassword'
                                        value={password.confirm}
                                        placeholder="Enter confirm password"
                                        onChange={e => setCnfPassword(e.target.value)}
                                        id='c_password' className=' bg-[#F7F7F7] rounded-[5px] p-3 focus:outline-0 font-[500] text-[12px] text-[#2B2B2B]' />
                                </div>
                            </div>

                            <button type="submit" className="primaryButton">
                                Submit
                            </button>
                            <div className="flex items-center justify-center gap-1">
                                <div className="text-sm link cursor-pointer">Resend link</div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ChangePassword