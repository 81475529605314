import { useMemo, useState } from "react";
import { ActionButton, ProfileImg, } from "../../components/controls";
import DataTableComponent from "../../components/datatable/DataTableComponent";
import { useSelector } from "react-redux";
import { customFilter } from "../../utils";


const columns = [
  {
    name: "ID",
    selector: (row) => row.id,
    width: "5%",
  },
  {
    name: "Profile",
    cell: (row) => <ProfileImg image={row.profile_image} />,
    width: "8%",
  },
  {
    name: "Vendor Name",
    selector: (row) => row.username,
    width: "15%",
  },
  {
    name: "Contact No.",
    selector: (row) => row.phone_number,
    width: "15%",

  },
  {
    name: "Email ID",
    selector: (row) => row.email,
    width: "25%",
  },
  {
    name: "Category",
    selector: (row) => row.category,
  },
  {
    name: "Action",
    width: "8%",
    cell: (row) => <ActionButton row={row} hideSee />
  },
]


const Vendors = () => {
  const [filterText, setFilterText] = useState('');
  const { vendorList } = useSelector(state => state.user)


  const filteredItems = useMemo(() => {
    return customFilter(vendorList, [{ id: 'global-filter', value: filterText }], ['id', 'username', 'phone_number', 'email', 'role', 'category']);
  }, [filterText, vendorList]);


  return (
    <>
      <DataTableComponent
        columns={columns}
        data={filteredItems}
      />
    </>
  )
}

export default Vendors
