import { useSelector } from 'react-redux';
import { Notification } from '../assets'
import { Link } from 'react-router-dom';
import { ProfileImg } from '../components/controls';

const Navbar = ({ module }) => {
	const { user } = useSelector((state) => state.auth);
	return (
		<header className="text-gray-600 body-font">
			<div className="flex flex-wrap px-10 py-5 items-center justify-between">
				<div className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
					<h3 className="text-[28px] capitalize font-medium">{module}</h3>
				</div>
				<div className="profile flex gap-5 items-center">
					{/* {user?.role === 'super_admin' && <div className="notif p-[4px] rounded-full bg-[#E2FBD7] overflow-hidden">
						<div className="notify-inner relative">
							 
							<Notification height="32" width="32" className="relative" />
							<span className="absolute top-[7px] right-[7px] h-2 w-2 bg-red-600 rounded-full "></span>
						</div>
					</div>} */}
					<Link to={`/profile/${user?.id}`} className="flex gap-5 items-center">
						<ProfileImg image={user?.profile_image} height={48} width={48} />
						<span>{user?.username}</span>
					</Link>
				</div>
			</div>
		</header>
	);
};

export default Navbar;
