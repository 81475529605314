import DataTable from 'react-data-table-component';
import './datatable.css'

const DataTableComponent = (props) => {


    const tableStyle = {
        table: {
         style:{
          
         }
        },
        headRow: {
            style: {

                fontSize: '14px',
                fontWeight: '600',
                justifyContent: 'space-between',
                backgroundColor: '#F1F1F1',
                borderRadius: "10px",
                marginBottom: "15px",
                border: "none",
                textTransform: "capitalize",
                width:"100%",
                padding:"0 10px",
            },
        },
        rows: {
            style: {
                fontSize:"12px",
                fontWeight: '400',
                justifyContent: 'space-between',
                backgroundColor: '#FFF',
                borderRadius: "10px",
                marginBottom: "10px",
                border: "1px solid #D1D1D1",
                padding:"0 10px",
            
            },
        },
    }

    return (
        <div className='datatable'>

            <DataTable            
                responsive
                highlightOnHover
                customStyles={tableStyle}
                fixedHeader
                paginationRowsPerPageOptions={[
                    7,20,50, 100
                ]}
                defaultSortFieldId={1}
                paginationPerPage={50}
                noDataComponent={<div>No Data Found</div>}
                subHeader
                {...props}
            />
        </div>
    )
}

export default DataTableComponent
