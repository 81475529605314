import { useSelector } from 'react-redux';
import { Navigate, Outlet} from 'react-router-dom';
import { Navbar} from '../../layouts';
import HasAccess from '../hasAccess/HasAccess';

const PrivateRoute = ({ component: Component, permissions, displayName, ...props }) => {
	const { accessToken } = useSelector(state => state.auth)
	if (!accessToken) return <Navigate to="/" />
	return (
    <HasAccess permissions={permissions}>
		<div className="flex flex-col">
			<div className="w-full">
				<Navbar module={displayName} />
			</div>
			<div className="px-10 md:w-full">
					<Outlet {...props} />
			</div>
		</div>
	</HasAccess>
	);
};

export default PrivateRoute;
