import { useCallback, useMemo, useState } from "react";
import { Filter, Search, Button, ActionButton, ProfileImg } from "../../components/controls";
import { Plus } from "../../assets";
import DataTableComponent from "../../components/datatable/DataTableComponent";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { customFilter } from "../../utils";

const columns = [
  {
    id: "id",
    name: "ID",
    selector: row => row.id,
    width: "7%",
  },
  {
    name: "Profile",
    cell: (row) => <ProfileImg image={row.profile_image} />,
    width: "8%",
  },
  {
    name: "User Name",
    selector: (row) => row.username,
    width: "15%",
  },
  {
    name: "Contact No.",
    selector: (row) => row.phone_number,
    width: "15%",

  },
  {
    name: "Email ID",
    selector: (row) => row.email,
    width: "25%",
  },
  {
    name: "Access",
    selector: (row) => {
      const permissions = row?.permissions || [];
      const filteredNames = permissions
        .filter(obj => obj.name?.toLowerCase() !== 'dashboard')
        .map(obj => obj.name);
      return filteredNames.join(', ');
    },
    width: "20%",
  },
  {
    name: "Action",
    width: "10%",
    cell: (row) => <ActionButton hideSee row={row} />
  },
]

const accessyOpt = [
  { value: "", label: 'Select Access' },
  { value: 'categories', label: 'Categories' },
  { value: 'tickets', label: 'Tickets' },
  { value: 'properties', label: 'Properties' },
  { value: 'vendors', label: 'Vendors' },
];
const Users = () => {
  const [filterText, setFilterText] = useState('');
  const [filter, setFilter] = useState('');
  const { list } = useSelector(state => state.user); // Assuming you're using Redux

  const filterTickets = (tickets, filterParam) => {
    return tickets.filter(ticket => !filterParam || ticket.permissions.some(perm => perm.name === filterParam));
  };

  const filteredItems = useMemo(() => {
    const filteredTickets = filterTickets(list, filter);
    return customFilter(filteredTickets, [{ id: 'global-filter', value: filterText }], ['id', 'phone_number', 'username', 'email']);
  }, [filterText, list, filter]);

  const handleChange = useCallback((name, value) => {
    setFilter(value);
  }, []);

  

  const subHeaderComponentMemo = useMemo(() => {

    return (
      <div className='subheader'>
        <Search onFilter={(e) => setFilterText(e.target.value)} placeholder="Search Users" filterText={filterText} />
        <div className="flex gap-4">
          <Filter options={accessyOpt} name="category" onChange={handleChange} className='w-[125px]' />
          <Link to="/users/create" className="flex">
            <Button classNames="bg-[#016268] h-full w-full text-[#fff] ">Add New <Plus className="fill-white" /></Button>
          </Link>
        </div>
      </div>

    );
  }, [filterText, handleChange]);


  return (
    <>
      <DataTableComponent
        defaultSortFieldId={"id"}
        columns={columns}
        data={filteredItems}
        subHeaderComponent={
          subHeaderComponentMemo
        }
        pagination
        defaultSortAsc={false}
      />
    </>
  )
}

export default Users
