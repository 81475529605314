import { createSlice } from '@reduxjs/toolkit';
import {
	forgotPassword,
	loginUser,
	logoutUser,
	resetPassword,
	updateUsers,
	verifyUserDetails,
} from './authActions';

const accessToken = localStorage.getItem('accessToken')
	? localStorage.getItem('accessToken')
	: null;

const initialState = {
	loading: false,
	user: null,
	modules: [],
	accessToken: accessToken,
	error: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			state.user = action.payload.user;
			state.accessToken = action.payload.accessToken;
			localStorage.setItem('accessToken', action.payload.accessToken);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload.user;
				state.modules = action.payload.modules;
				state.accessToken = action.payload.accessToken;
				localStorage.setItem('accessToken', action.payload.accessToken);
				state.error = null;
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(logoutUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(logoutUser.fulfilled, (state) => {
				state.loading = false;
				state.user = null;
				state.accessToken = null;
				localStorage.removeItem('accessToken');
				state.error = null;
			})
			.addCase(logoutUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(verifyUserDetails.pending, (state) => {
				state.loading = true;
			})
			.addCase(verifyUserDetails.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload.user;
				state.modules = action.payload.modules;
				state.error = null;
			})
			.addCase(verifyUserDetails.rejected, (state, payload) => {
				state.loading = false;
				state.accessToken = null;
				localStorage.removeItem('accessToken');
				state.user = null;
				state.error = payload.error;
			})
			.addCase(updateUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateUsers.fulfilled, (state, action) => {
				state.loading = false;
				let data = action.payload.data
				state.user = {
					...data,
					profile_image: typeof data.profile_image === 'string' ? data.profile_image : JSON.stringify(data.profile_image)
				};
				state.error = null;
			})
			.addCase(updateUsers.rejected, (state, payload) => {
				state.loading = false;
				state.error = payload.error;
			})
			.addCase(resetPassword.pending, (state) => {
				state.loading = true;
			})
			.addCase(resetPassword.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(resetPassword.rejected, (state, payload) => {
				state.loading = false;
				state.error = payload.error;
			})
			.addCase(forgotPassword.pending, (state) => {
				state.loading = true;
			})
			.addCase(forgotPassword.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(forgotPassword.rejected, (state, payload) => {
				state.loading = false;
				state.error = payload.error;
			});
	},
});

export default authSlice.reducer;

export const { setCredentials } = authSlice.actions;
