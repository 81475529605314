import React, { useState } from 'react';
import Select from 'react-select';

const options = [
    { id: 2, value: 'categories', label: 'Categories' },
    { id: 3, value: 'tickets', label: 'Tickets' },
    { id: 4, value: 'properties', label: 'Properties' },
    { id: 5, value: 'vendors', label: 'Vendors' },
];

const CheckboxOption = ({ innerProps, label, isSelected,  }) => (
    <div {...innerProps} className='flex items-center'>
        <input type="checkbox" checked={isSelected} readOnly />&nbsp;
        {label}
    </div>
);

const DropDown = ({ setSelectedOptions, selectedOptions, setUserData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleSelectChange = (selectedValues) => {
        selectedValues = selectedValues.map((e) => ({ id: e.id, value: e.value, label: e.value }))
        setSelectedOptions(selectedValues);
        setUserData(prev => ({ ...prev, module: selectedValues.map((e) => e.id) }))
    };
    const customStyles = {
        option: (provided) => ({
            ...provided,
            background: 'transparent',
            '&:active': {
                background: 'transparent', // Remove active color
            },
        }),
    };

    return (
        <div onClick={() => setIsOpen(true)} onBlur={() => setIsOpen(false)} className='pointer'>
            <Select
                options={options.map((option) => ({
                    ...option,
                    label: (
                        <CheckboxOption
                            label={option.label}
                            isSelected={selectedOptions?.some(
                                (selectedOption) => selectedOption.value === option.value
                            )} />),
                }))}
                isMulti
                className='bg-transparent focus:outline-0'
                onChange={handleSelectChange}
                value={selectedOptions||''}
                components={{
                    IndicatorSeparator: () => null
                }}
                menuIsOpen={isOpen}
                hideSelectedOptions={false}
                isClearable={false}
                // controlShouldRenderValue={false}
                placeholder="Select access"
                styles={customStyles}
            />

        </div>
    );
};

export default DropDown;
