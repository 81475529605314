
import Select from 'react-select';
import PriorityIcon from '../../pages/tickets/PriorityIcon';

const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps} className='flex  items-center px-5 py-1 cursor-pointer '>
    <PriorityIcon color={data.iconColor} />
    <span style={{ marginLeft: '8px' }}>{label}</span>
  </div>)

const options = [
  { value: 'normal', label: 'Normal', iconColor: "green" },
  { value: 'high', label: 'High', iconColor: "yellow" },
  { value: 'urgent', label: 'Urgent', iconColor: "red" },
];


const CustomPlaceholder = () => (
  <div className='absolute flex items-center gap-1 left-2 text-[#A2A2A2] text-[1px]'>
    <PriorityIcon style={{ marginRight: '8px' }} color={"#A2A2A2"} />
    Choose priority
  </div>
);
const SingleSelect = ({ setPriority, priority }) => {
  const handleSelectChange = (selectedValues) => {
    setPriority(selectedValues);
  };

  return (
    <div>
      <Select
        options={options}
        components={{
          Option: CustomOption,
          IndicatorSeparator: () => null, Placeholder: CustomPlaceholder
        }}
        className='bg-transparent focus:outline-0 pointer priority-dropdown'
        onChange={handleSelectChange}
        value={priority}
        isSearchable={false}
        placeholder={"🏳️"}

      />

    </div>
  );
};

export default SingleSelect;
