import { createSlice } from '@reduxjs/toolkit';
import {
	getProperties,
	getUser, getVendor, setUsers, updateUsers, updateVendors
} from './userActions';

const initialState = {
	loading: false,
	list: [],
	vendorList: [],
	propertieList: [],
	error: null
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUser: (state, action) => {
			state.user = action.payload.user;
			state.accessToken = action.payload.accessToken;
			localStorage.setItem('accessToken', action.payload.accessToken);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(getUser.fulfilled, (state, action) => {
				state.loading = false;
				state.list = action.payload.data;
				state.modules = action.payload.modules;
				state.error = null;
			})
			.addCase(getUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(getVendor.pending, (state) => {
				state.loading = true;
			})
			.addCase(getVendor.fulfilled, (state, action) => {
				state.loading = false;
				state.vendorList = action.payload.data;
				state.modules = action.payload.modules;
				state.error = null;
			})
			.addCase(getVendor.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(getProperties.pending, (state) => {
				state.loading = true;
			})
			.addCase(getProperties.fulfilled, (state, action) => {
				state.loading = false;
				state.propertieList = action.payload.data;
				state.modules = action.payload.modules;
				state.error = null;
			})
			.addCase(getProperties.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(setUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(setUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(setUsers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(updateUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateUsers.fulfilled, (state, { payload }) => {
				state.loading = false
				state.error = null;
			})
			.addCase(updateUsers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(updateVendors.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateVendors.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.error = null;
			})
			.addCase(updateVendors.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
	},
});

export default userSlice.reducer;

export const { updateUser } = userSlice.actions;
