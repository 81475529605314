import React from 'react'

const ProfileImg = ({ image, height, width, alt, className, src, defaultClass, ...props }) => {
    let imageUrl;
    if (image) {
        imageUrl = JSON.parse(image)?.path
    }
    return (
        (src || image) ?
            <img src={src || `${process.env.REACT_APP_URL}/${imageUrl}`} height={height || 40} width={width || 40} alt={alt || "Profile_picture"} className={`${!defaultClass && "rounded-full object-cover w-10 h-10"}  ${className}`} {...props} />
            :
            <img src={"https://tse4.mm.bing.net/th?id=OIP.FjSRywv2jSoO2kdlijRZ4gHaHa&pid=Api&P=0"} height={height || 40} width={width || 40} alt={alt || "Profile_picture"} className={`${!defaultClass && "rounded-full object-cover w-10 h-10"}  ${className}`} {...props} />
    )
}

export default ProfileImg