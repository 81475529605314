import { createSlice } from '@reduxjs/toolkit';
import { deleteNotification, getNotifications, setNotifications, updateNotifications } from './notificationAction';

const initialState = {
    loading: false,
    notificationList: [],
    notificationById: null,
    error: null
};

const userSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken;
            localStorage.setItem('accessToken', action.payload.accessToken);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationList = action.payload.data;
                state.error = null;
            })
            .addCase(getNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
            .addCase(setNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(setNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationList = [...state.notificationList, action.payload.data];
                state.error = null;
            })
            .addCase(setNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
            .addCase(updateNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateNotifications.fulfilled, (state, { payload }) => {
                state.loading = false;
                const updatedNotification = payload.data;
                const updatedList = state.notificationList.map(notification => {
                    if (notification.id === updatedNotification.id) return updatedNotification;
                    return notification;
                });
                state.notificationList = updatedList;
                state.error = null;
            })
            .addCase(updateNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
            .addCase(deleteNotification.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteNotification.fulfilled, (state, { payload }) => {
                state.loading = false;
                const id = payload.id;
                state.notificationList = state.notificationList.filter(e => e.id !== parseInt(id));
                state.error = null;
            })
            .addCase(deleteNotification.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
    },
});

export default userSlice.reducer;

export const { updateUser } = userSlice.actions;
