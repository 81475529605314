import React, { useEffect, useRef, useState } from 'react'
import { Button, Calendar, ProfileImg, SingleSelect } from '../../components/controls'
import { Delete, Upload } from '../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { errorToast, promiseToast } from '../../utils'
import { updateTickets } from '../../store/ticket/ticketAction'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

const UpdateTickets = () => {
    const initialState = {
        fault_details: '',
        address: '324 Upton Glen, United Arab Emirates, AE (ARE)',
        phone_number: '9876543210'
    }
    const { id } = useParams()
    const { ticketList } = useSelector(state => state.ticket)

    const [time_slot, setTime_slot] = useState("")
    const [continuity, setContinuity] = useState([])
    const [priority, setPriority] = useState([])
    const [category, setCategory] = useState([])

    const [data, setData] = useState(initialState)
    const { categoryList } = useSelector(state => state.category)

    const [removedImages, setRemovedImages] = useState([]);
    const [newImages, setNewImages] = useState([]);
    const [imagePreviewSrcs, setImagePreviewSrcs] = useState([]);
    const [oldImageFiles, setOldImageFiles] = useState([]);

    const [oldAnimationClasses, setOldAnimationClasses] = useState([]);
    const [animationClasses, setAnimationClasses] = useState([]);

    const navigate = useNavigate()
    const ref = useRef(null);
    const dispatch = useDispatch()
    useEffect(() => {
        let ticket = ticketList.find(c => c?.id === parseInt(id))
        if (ticket) {
            setData({
                fault_details: ticket.fault_details,
                address: ticket.address,
                phone_number: ticket.phone_number
            })
            setCategory(ticket.category)

            let oldImg = JSON.parse(ticket?.image_before)
            let oldanim = oldImg ? oldImg.map(e => `animate-jump-in`) : []
            setOldAnimationClasses(oldanim)
            setOldImageFiles(oldImg)
            setTime_slot(ticket?.time_slot)

            setContinuity(() => typeof ticket.continuity === 'object' ? ticket.continuity : JSON.parse(ticket.continuity))
            if (ticket.priority) {
                setPriority({
                    value: ticket?.priority,
                    label: ticket?.priority.charAt(0).toUpperCase() + ticket?.priority.slice(1)
                })
            }
        }

    }, [id, ticketList])

    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);
        const newAnimationClasses = [];
        if ((oldImageFiles.length + newImages.length + files.length) > 5) return errorToast("Can't upload more than 5 images")
        files.forEach((file) => {
            if (!file) return;
            const supportedFormats = ["image/jpeg", "image/png", "image/svg+xml", "image/webp", "image/ico"];
            const sizeInKB = parseFloat(file.size / 1024).toFixed(2);
            if (!supportedFormats.includes(file.type)) return errorToast("Invalid file type. Only JPEG/PNG/icons are allowed.");
            if (sizeInKB > 5000) return errorToast("File size is more than 5MB");
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreviewSrcs((prevSrcs) => [...prevSrcs, reader.result]);
                setAnimationClasses((prev) => [...prev, `animate-jump-in`]);
            };
            reader.readAsDataURL(file);
        });

        setNewImages((prevFiles) => [...prevFiles, ...files]);
        setAnimationClasses((prevClasses) => [...prevClasses, ...newAnimationClasses]);
    };

    const handleRemoveImage = (index) => {
        const newImageFiles = [...newImages];
        const newImagePreviewSrcs = [...imagePreviewSrcs];
        const newAnimationClasses = [...animationClasses];

        newAnimationClasses[index] = 'animate-jump-out';
        setAnimationClasses(newAnimationClasses);

        setTimeout(() => {
            newImageFiles.splice(index, 1);
            newImagePreviewSrcs.splice(index, 1);

            newAnimationClasses.splice(index, 1);

            setNewImages(newImageFiles);
            setImagePreviewSrcs(newImagePreviewSrcs);
            setAnimationClasses(newAnimationClasses);
        }, 500);
    };

    const handleRemoveOldImage = (index) => {
        const newOldImageFiles = [...oldImageFiles];
        const newAnimationClasses = [...oldAnimationClasses];
        newAnimationClasses[index] = 'animate-jump-out';
        setOldAnimationClasses(newAnimationClasses);

        setTimeout(() => {

            const removedImage = newOldImageFiles.splice(index, 1)[0];
            newAnimationClasses.splice(index, 1);

            setOldImageFiles(newOldImageFiles);
            setOldAnimationClasses(newAnimationClasses);

            setRemovedImages(prevRemovedImages => [...prevRemovedImages, removedImage]);
        }, 500);

    };

    const handleOnChange = e => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleCountinuityChange = e => {
        let dates = e.map(a => (a.format()))
        setContinuity(dates)
    }

    const clear = () => {
        setData(initialState)
        navigate(-1)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (data.phone_number === '') return errorToast("please Enter  Contact Number ")
        if (data.phone_number.length != 10) return errorToast("Contact Number must be 10 digit ")
        if (!category?.id) return errorToast("please select Category")
        if (!time_slot) return errorToast("please select Time Slot")
        if (!data.fault_details) return errorToast("please describe fault details")

        const passData = {
            ...data,
            time_slot: new Date(time_slot).toISOString(),
            priority: priority.value,
            category_id: category?.id,
            category_name: category?.name,
            continuity: continuity,
            removedImages: JSON.stringify(removedImages)
        }
        let res = await promiseToast("Updating Ticket...", null, dispatch(updateTickets({ data: passData, id, newImages })))
        if (res.error) return;
        clear()
    }


    return (
        <div className='category-wrapper mb-10' >
            <div className="labal mb-5">
                <h3 className='text-lg font-medium'>Select Category</h3>
            </div>
            <div className="category mb-[100px] flex gap-5 flex-wrap relative">
                {
                    categoryList.map(({ id, name, icon }) => {
                        return (
                            <label htmlFor={id} key={id} onClick={() => setCategory(() => { return { id, name, icon } })}>
                                <div className={`category-item p-5 border-2 border-[#D1D1D1] cursor-pointer rounded-[7px] flex justify-center items-center text-center flex-col gap-4 w-[250px] h-[150px] text-[14px] capitalize ${id === parseInt(category?.id) ? 'opened' : ''}`}>
                                    <div className="category-icon rounded-full w-[40px] h-[40px] bg-[#E2FBD7] flex items-center justify-center">
                                      
                                        <ProfileImg image={icon} />
                                    </div>
                                    <div className="category-name">
                                        <span className='font-medium'>{name}</span>
                                    </div>
                                    <input type="radio" hidden name="category" value={name} id={id} />
                                </div>
                            </label>
                        )
                    })
                }
            </div>

            <form className="form-control ticketForm p-8 flex gap-5 relative flex-col mb-5" onSubmit={handleSubmit} >

                <div className="category-preview absolute -top-10 left-0">
                    <h2 className='text-lg font-medium capitalize'>{category?.name || "Select Category"}</h2>
                </div>
                <div className="control-wrapper">
                    <label htmlFor="fault_details">Fault Details*</label>
                    <textarea id='fault_details' name='fault_details' aria-label='fault_details' value={data.fault_details} onChange={handleOnChange} className='inputField' required />
                </div>

                <div className="flex gap-8">
                    <div className='control-wrapper'>
                        <div className="controls text-[14px] flex flex-col gap-3 w-full">
                            <label htmlFor="file">Upload Images*</label>
                            <button
                                type="button"
                                onClick={() => ref.current.click()}
                                className="upload border border-[#D1D1D1] rounded-[5px] py-2 px-3  flex gap-2 items-center"
                            >
                                <Upload width={25} /> Upload images here
                            </button>

                            <input
                                type="file"
                                id="file"
                                accept="image/*"
                                hidden
                                ref={ref}
                                onChange={handleImageChange}
                                placeholder="Drag and drop the file to upload"
                                className="focus:outline-0 w-full"
                                multiple
                            />

                        </div>
                    </div>

                    <div className="control-wrapper">
                        <label htmlFor="distination">Confirm Address*</label>
                        <input type="text" id='address' name='address' aria-label='address' value={data.address} onChange={handleOnChange} className='inputField w-full' required />
                    </div>
                </div>

                <div className="flex gap-8">
                    <div className="flex w-100 flex-wrap">
                        
                        {!!oldImageFiles.length && oldImageFiles.map((src, index) => {
                            if (!src) return (<></>);
                            return (
                                <div key={index} className={`flex justify-center items-center w-20 animate-once animate-ease-in-out  ${oldAnimationClasses[index]}`}>
                                    <div className="relative inline-flex items-center ">
                                        <ProfileImg image={JSON.stringify(src)} className={"w-16 h-16  rounded-sm "} />
                                        <span className="sr-only ">Notifications</span>
                                        <div >
                                            <button type="button" onClick={() => handleRemoveOldImage(index)} className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-[#e2fbd7] border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                                                <Delete width={15} height={15} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {!!imagePreviewSrcs.length && imagePreviewSrcs.map((src, index) => {
                            return (
                                <div key={index} className={`flex justify-center items-center w-20 animate-once animate-ease-in-out  ${animationClasses[index]}`}>
                                    <div className="relative inline-flex items-center ">
                                        <ProfileImg src={src} className={"w-16 h-16  rounded-sm "} />
                                        <div >
                                            <button type="button" onClick={() => handleRemoveImage(index)} className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-[#e2fbd7] border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                                                <Delete width={15} height={15} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex gap-8">
                    <div className="control-wrapper">
                        <label htmlFor="name">Confirm Contact Number*</label>
                        <input type="number" id='phone_number' name='phone_number' aria-label='phone_number' value={data.phone_number} onChange={handleOnChange} className='inputField' required />
                    </div>
                    <div className="control-wrapper">
                        <label htmlFor="date">Select Time & Date*</label>
                        <Calendar
                            format="MM/DD/YYYY HH:mm"
                            showTime
                            minDate={new Date()}
                            name="time_slot"
                            id="time_slot"
                            aria-label='time_slot'
                            value={time_slot}
                            onChange={(e) => setTime_slot(e?.format())}
                            inputClass=' rounded-[5px] py-1 px-3 focus:outline-0; text-sm w-full'
                            required

                        />
                    </div>
                </div>
                <div className="flex gap-8">
                    <div className="control-wrapper">
                        <label htmlFor="name">Priority</label>
                        <SingleSelect priority={priority} setPriority={setPriority} />
                    </div>
                    <div className="control-wrapper">
                        <label htmlFor="continuity">Select Continuity</label>
                        <Calendar

                            minDate={new Date()}
                            value={continuity}
                            onChange={handleCountinuityChange}
                            inputClass=' rounded-[5px] py-1 px-3 focus:outline-0; text-sm w-full'
                            aria-label='continuity'
                            name="continuity"
                            id="continuity"
                            multiple
                        />
                    </div>
                </div>
                <div className="buttons bottom-[-75px] left-0 flex h-[50px] w-[300px] gap-5">
                    <Button type='submit' classNames="bg-[#016268] h-[50px] w-[150px] text-[#fff] ">Submit</Button>
                    <Button type="reset" classNames="bg-[#F1F1F1] h-[50px] w-[150px]" onClick={clear}  >Cancel</Button>
                </div>
            </form>

        </div>)
}

export default UpdateTickets;
