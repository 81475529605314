import { createSlice } from '@reduxjs/toolkit';
import { closeChat, initChat, sendMessage } from './chatActions';
import { errorToast } from '../../utils';

const initialState = {
	loading: false,
	chat1: {},
	chat2: {},
	error: null
};

const chatSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder
			.addCase(initChat.pending, (state) => {
				state.loading = true;
			})
			.addCase(initChat.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				const id = action.payload.data.ticket_id
				if (Object.keys(state.chat1).length === 0 || state.chat1?.ticket_id === id) {
					if (state.chat2.ticket_id === id) {
						state.chat2 = action.payload.data
						localStorage.setItem("chat2", id)
						return state
					}
					state.chat1 = action.payload.data
					localStorage.setItem("chat1", id)
					return state
				}
				else if (Object.keys(state.chat2).length === 0 || state.chat2?.ticket_id === id) {
						state.chat2 = action.payload.data
						localStorage.setItem("chat2", id)
					return state
				}
				else {
					return errorToast("Please close opened chat first !")
				}
			})
			.addCase(initChat.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(sendMessage.pending, (state, action) => {
				state.loading = true
				if (state.chat1.id === action.meta.arg.chat_id) {
					state.chat1.messages = [...state.chat1.messages, action.meta.arg]
				}
				else if (state.chat2.id === action.meta.arg.chat_id) {
					state.chat2.messages = [...state.chat2.messages, action.meta.arg]
				}
				return state;
			})
			.addCase(sendMessage.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(sendMessage.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
			.addCase(closeChat.pending, (state, action) => {
				state.loading = true
			})
			.addCase(closeChat.fulfilled, (state, action) => {
				state.loading = false;
				let id = action.payload.data.id

				if (state.chat1.id === id) {
					state.chat1 = {}
					localStorage.removeItem("chat1", id)
					
				}
				else if (state.chat2.id === id) {
					state.chat2 = {}
					localStorage.removeItem("chat2", id)
				}
				return state
			})
			.addCase(closeChat.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			})
	},
});

export default chatSlice.reducer;

export const {} = chatSlice.actions;
