import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Logo, Email } from '../../assets';
import { forgotPassword } from '../../store/auth/authActions';
import { errorToast, promiseToast } from '../../utils';

export const Forgot = () => {
	const { user } = useSelector((state) => state.auth);
	const [email, setEmail] = useState('')
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {

	}, [navigate, user]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (email === '') return errorToast("Email cannot be black, Please enter email")
		let res = await promiseToast("Validating...", null, dispatch(forgotPassword({ email })))
		if (res.error) return;
		setEmail('')
		navigate('/')
	};

	return (
		<section className="bg-gray-50 dark:bg-gray-900 ">
			<div className="flex flex-col items-center bg-[#f1f1f1] justify-center px-6 py-4 mx-auto md:h-screen lg:py-0">

				<div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 relative ">
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
						<div className="logo absolute top-[-100px] left-[50%] translate-x-[-50%] ">
							<Logo className="w-[200px] h-[75px] sidebar-logo " />
						</div>
						<div className="heading">
							<h1 className="text-xl font-[500] leading-tight tracking-tight text-gray-900 md:text-3xl  text-center">
								Forgot Password?
							</h1>
							<p className='text-center mt-2 text-[14px]'>Please enter your registered email ID.</p>
						</div>
						<form className="space-y-4 md:space-y-4" onSubmit={handleSubmit}>
							<div className='inputWrapper flex items-center justify-center gap-2 bg-[#F7F7F7] w-full p-3 rounded-[5px]'>
								<Email height={20} width={20} />
								<input className="focus:outline-0 w-full bg-transparent font-[500]" name="email" placeholder="Email" value={email} type="email" onChange={e => setEmail(e.target.value)} required />
							</div>

							<button type="submit" className="primaryButton">
								Submit
							</button>
							<div className="flex items-center justify-center gap-1">
								<span>Back to</span> <NavLink to="/" className="text-sm link">Login</NavLink>
							</div>

						</form>
					</div>
				</div>
			</div>
		</section>
	);
};
