
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';


export const getTickets = createAsyncThunk('ticket/get', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get('ticket/getList');
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const setTickets = createAsyncThunk('ticket/set', async ({ data, image_before }, { rejectWithValue }) => {
    try {
        let formData = new FormData();
        if (image_before.length) {
            image_before.forEach(file => {
                formData.append('file', file);
            });
        }
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                formData.append(key, data[key]);
            }
        }
        const response = await axiosConfig.post('ticket/create', formData);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const updateTickets = createAsyncThunk('ticket/update', async ({ data, id, newImages }, { rejectWithValue }) => {
    try {
        let formData = new FormData();
        if (newImages.length) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            
            if (newImages.length) {
                newImages.forEach(file => {
                    formData.append('file', file);
                });
            }
        } else {
            formData = data
        }
        const response = await axiosConfig.patch(`ticket/update/${id}`, formData);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});


export const updateStatus = createAsyncThunk('ticket/update/status', async ({ id }, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.patch(`ticket/closed/${id}`);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});
