import React from 'react';

const Menu = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="Menu" height="25" width="25">
            <path d="M12,7a2,2,0,1,0-2-2A2,2,0,0,0,12,7Zm0,10a2,2,0,1,0,2,2A2,2,0,0,0,12,17Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z" fill={color || '#016268'}>
            </path>
        </svg>
    );
};

export default Menu;
