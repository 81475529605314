
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';


export const getNotifications = createAsyncThunk('notification/get', async (_, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.get('notification/getList');
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const setNotifications = createAsyncThunk('notification/set', async (notification, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.post('notification/create', notification);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const updateNotifications = createAsyncThunk('notification/update', async ({ id, notification }, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.patch(`notification/update/${id}`, notification);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});


export const deleteNotification = createAsyncThunk('notification/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.delete(`notification/delete/${id}`);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});