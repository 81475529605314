import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';


export const initChat = createAsyncThunk('chat/initChat', async (id, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.post('chat/initiateChat', { id: id });
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const sendMessage = createAsyncThunk('chat/sendMessage', async (data, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.post('chat/send', data);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});

export const closeChat = createAsyncThunk('chat/close', async (id, { rejectWithValue }) => {
    try {
        const response = await axiosConfig.patch(`chat/close/${id}`);
        return await response.data;
    } catch (error) {
        return rejectWithValue({ error: error.response?.data?.message || error.response?.data?.error || error.message || 'something went wrong' });
    }
});
