import { Navigate, Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './app.css';
import { Dashboard, ErrorPage, Login, NotFound } from '../pages';
import { PrivateRoute } from '../components';
import { Sidebar } from '../layouts';
import { useDispatch, useSelector } from 'react-redux';
import Properties from '../pages/properties/Properties';
import Vendors from '../pages/vendors/Vendors';
import Users from '../pages/users/Users';
import CreateUser from '../pages/users/CreateUser';
import Categories from '../pages/categories/Categories';
import CreateCategories from '../pages/categories/CreateCategories';
import Profile from '../pages/profile/Profile';
import Notifications from '../pages/notifications/Notifications';
import CreateNotification from '../pages/notifications/CreateNotification';
import { useEffect } from 'react';
import { verifyUserDetails } from '../store/auth/authActions';
import { Forgot } from '../pages/forgot_password/Forgot';
import EditCategories from '../pages/categories/updateCategories';
import UpdateUser from '../pages/users/UpdateUser';
import EditNotification from '../pages/notifications/EditNotification';
import { CreateTicket, TicketDetails, Tickets, UpdateTicket } from '../pages/tickets';
import UpdateVendor from '../pages/vendors/UpdateVendor';
import ChangePassword from '../pages/forgot_password/ChangePassword';
import Message from '../components/chat/Message';

const router = accessToken => createBrowserRouter(
	createRoutesFromElements(
		<Route element={<Layout />} errorElement={<ErrorPage />}>

			{accessToken && (<Route index element={<Navigate to="/dashboard" />} />)}
			<Route path="dashboard" element={<PrivateRoute permissions="dashboard" displayName="Dashboard" />} >
				<Route index element={<Dashboard />} />
			</Route>

			<Route path="/tickets" element={<PrivateRoute permissions="tickets" displayName="Tickets" />} >
				<Route index element={<Tickets />} />
			</Route>

			<Route path="/tickets/update/:id" element={<PrivateRoute permissions="tickets" displayName="Edit Ticket Details" />} >
				<Route index element={<UpdateTicket />} />
			</Route>

			<Route path="/tickets/view/:id" element={<PrivateRoute permissions="tickets" displayName="Ticket Details" />} >
				<Route index element={<TicketDetails />} />
			</Route>
			<Route path="/tickets/create" element={<PrivateRoute permissions="tickets" displayName="Add New Ticket" />} >
				<Route index element={<CreateTicket />} />
			</Route>

			<Route path="/properties" element={<PrivateRoute permissions="properties" displayName="properties" />} >
				<Route index element={<Properties />} />
			</Route>

			<Route path="/categories" element={<PrivateRoute permissions="categories" displayName={"Categories"} />} >
				<Route index element={<Categories />} />
			</Route>
			<Route path="/categories/create" element={<PrivateRoute permissions="categories" displayName="add Category" />} >
				<Route index element={<CreateCategories />} />
			</Route>

			<Route path="/categories/update/:id" element={<PrivateRoute permissions="categories" displayName="Edit Categories" />} >
				<Route index element={<EditCategories />} />
			</Route>

			<Route path="/vendors" element={<PrivateRoute permissions="vendors" displayName="Vendors" />} >
				<Route index element={<Vendors />} />
			</Route>
			<Route path="/vendors/update/:id" element={<PrivateRoute permissions="vendors" displayName="Edit Vendor" />} >
				<Route index element={<UpdateVendor />} />
			</Route>
			<Route path="/users" element={<PrivateRoute permissions="users" displayName="Users" />} >
				<Route index element={<Users />} />
			</Route>
			<Route path="/users/create" element={<PrivateRoute permissions="users" displayName="Add New User" />} >
				<Route index element={<CreateUser />} />
			</Route>

			<Route path="/users/update/:id" element={<PrivateRoute permissions="users" displayName="Edit User" />} >
				<Route index element={<UpdateUser />} />
			</Route>

			<Route path="/notifications" element={<PrivateRoute permissions="notifications" displayName="Notifications" />} >
				<Route index element={<Notifications />} />
			</Route>
			<Route path='/notifications/create' element={<PrivateRoute permissions="notifications" displayName="Add New User" />} >
				<Route index element={<CreateNotification />} permissions="notifications" />
			</Route>
			<Route path="/notifications/update/:id" element={<PrivateRoute permissions="notifications" displayName="Edit notifications" />} >
				<Route index element={<EditNotification />} />
			</Route>

			<Route path="/profile/:id" element={<PrivateRoute permissions="dashboard" displayName="My Profile" />} >
				<Route index element={<Profile />} />
			</Route>

			<Route path='/' element={<Login />} />
			<Route path='/forgot'>
				<Route index element={<Forgot />} />
			</Route>
			<Route path="/auth/reset-password/:token" element={<ChangePassword />} />
			<Route path="*" element={<NotFound />} />
		</Route>
	))
const App = () => {
	const { accessToken } = useSelector((state) => state.auth);
	return (
		<RouterProvider router={router(accessToken)} fallbackElement={<span>Loading...</span>} />
	)
}

const Layout = () => {
	const { accessToken } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (accessToken) {
			dispatch(verifyUserDetails());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="main-container">
			<Toaster />

			{accessToken &&
				<div className="max-w-[250px] md:w-72">
					<Sidebar />
					<Message />
				</div>
			}


			<div className={`content-container`}>
				<Outlet />
			</div>
		</div>
	)
}

export default App;
