import { CircularProgressbar } from 'react-circular-progressbar';

const Progress = ({data,bg,bar}) => {

    return (
        <>
                <CircularProgressbar
                    value={data}
                    text={`${data}%`}
                    strokeWidth="8"
                    background
                    circleRatio
                    styles={{
                        root: {width: '90px', height: '90px'},
                       
                        path: {
                            stroke: bar,
                            strokeLinecap: 'round',
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                            width:'30px'
                        },
                        trail: {
                            stroke:bg,
                            strokeLinecap: 'butt',
                        },
                        text: {
                            fill: '#000',
                            transform: 'translate(-10px, 5px)',
                            fontSize: '16px',
                            fontWeight:500
                        },
                        background: {
                            fill: bg,
                        },
                    }}
                />
        </>
    )
}

export default Progress
