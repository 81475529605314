import { useMemo, useState } from "react";
import { ActionButton, Button, ProfileImg, Search } from "../../components/controls";
import {  Plus } from "../../assets";
import DataTableComponent from "../../components/datatable/DataTableComponent";
import { Link } from "react-router-dom";
import { customFilter } from "../../utils";
import { useSelector } from "react-redux";
import { deleteCategory } from "../../store/category/categoryAction";


const columns = [
  {
    name: "ID",
    id:"id",
    selector: (row) => row.id,
    width: "5%",
    
  },
  {
    name: "Icon",
    cell: (row) => <ProfileImg image={row.icon} />,
    width: "8%",
  },
  {
    name: "Category",
    selector: (row) => row.name,
    width: "auto",
  },
  {
    name: "Action",
    width: "8%",
    cell: (row) => <ActionButton row={row} path={`notifications`} hideSee canDelete deleteCall={deleteCategory(row.id)} />
  },
]
const Categories = () => {
  const [filterText, setFilterText] = useState('');
  const { categoryList } = useSelector(state => state.category)
  const filteredItems = useMemo(() => {
    return customFilter(categoryList, [{ id: 'global-filter', value: filterText }], ['id', 'name',]);
  }, [filterText, categoryList]);

  const subHeaderComponentMemo = useMemo(() => {

    return (
      <div className='subheader'>
        <Search onFilter={(e) => setFilterText(e.target.value)} placeholder="Search Categories" filterText={filterText} />
        <div className="flex gap-4">

          <Link to="/categories/create" className="flex">
            <Button classNames="bg-[#016268] h-full w-full text-[#fff] ">Add New <Plus className="fill-white" /></Button>
          </Link>
        </div>
      </div>

    );
  }, [filterText]);


  return (
    <>
      <DataTableComponent
        columns={columns}
        data={filteredItems}
        subHeaderComponent={
          subHeaderComponentMemo
        }
        defaultSortAsc={false}
        defaultSortFieldId={'id'}
      />
    </>
  )
}

export default Categories
