import { useMemo, useEffect, useState, useCallback, useRef } from "react";
import { ActionButton, Button, Calendar, Filter, Search } from "../../components/controls";
import { FlagHigh, FlagNormal, FlagUrgent, Image, Plus, Reset } from "../../assets";
import DataTableComponent from "../../components/datatable/DataTableComponent";
import "./ticket.css"
import { Link } from "react-router-dom";
import ImagePreview from "./modal/ImagePreview";
import { useSelector } from "react-redux";
import moment from 'moment';
import { customFilter } from "../../utils";
import { Dropdown } from "../../components";

const priorityIcons = {
  urgent: <FlagUrgent height="10" width="10" />,
  high: <FlagHigh height="10" width="10" />,
  normal: <FlagNormal height="10" width="10" />,
};

const statusSortOrder = {
  'pending': 0,
  'ongoing': 1,
  'completed': 2,
  'closed': 3
};

const prioritySortOrder = {
  'urgent': 5,
  'high': 4,
  'normal': 3,
  'null': 2
};

const caseInsensitiveSort = (a, b) => {
  const statusComparison = statusSortOrder[a.status] - statusSortOrder[b.status];

  if (statusComparison !== 0) {
    return statusComparison;
  } else {
    const priorityComparison = prioritySortOrder[b.priority] - prioritySortOrder[a.priority];
    if (priorityComparison !== 0) {
      return priorityComparison;
    } else {
      return b.id - a.id;
    }
  }
};

const compact = 2
const columns = (toggleModal, user) => [
  {
    id: "id",
    name: "ID",
    selector: (row) => row.id,
    width: "5%",
    // compact
  },
  {
    id: "raiseOn",
    name: "Raised On",
    selector: (row) => moment(row.createdAt).format('L'),
    width: '8%',
    compact,

  },
  {
    id: "propertyName",
    name: "Property Name",
    selector: (row) => "M22 Investment",
    compact,
    width: "10%",
  },
  {
    id: "category",
    name: "Category",
    selector: (row) => row.category?.name,
    // compact,
    width: "16%",
  },
  {
    id: "priority",
    name: "Priority",
    selector: ({ priority }) => (<div className="priority flex items-center h-full justify-end gap-2 capitalize">
      <div className="flag justify-end">{priorityIcons[priority] || '-'}</div>
      {priority}
    </div>
    ),
    // compact,
    width: "12%",
  },
  {
    id: "imagesBefore",
    name: "Images Before",
    selector: ({ image_before }) => <div className="pointer"><Image height="20" width="20" onClick={() => toggleModal(image_before)} /></div>,
    // compact,
    width: "8%",
  },
  {
    id: "imagesAfter",
    name: "Images After",
    // compact,
    selector: ({ image_after }) => <div>{image_after ? <div className="cursor-pointer"><Image height="20" width="20" onClick={() => toggleModal(image_after)} /></div> : <Image height="20" width="20" style={{ fill: "#A2A2A2" }} className="cursor-not-allowed" title="Images not available" />}
    </div>,
    width: "8%",
  },
   {
    id: "closedOn",
    name: "Closed On",
    compact,
    selector: (row) => row.closed_on ? moment(row.closed_on).format("L") : "-",
    center: 'true',
    width: "10%",
  },
  {
    id: "status",
    name: "Status",
    compact,
    selector: (row) => (
      <div type="button" className={`btn ${row.status} cursor-default `}>
        {row.status}
      </div>
    ),
    sortable: true,
    sortFunction: caseInsensitiveSort,
    width: "10%",
  },
  {
    id: "action",
    name: "Action",
    width: "5%",
    compact,
    cell: (row) => (<>
      <ActionButton canEdit={user?.role !== 'manager' || row.status === 'closed'} row={row} path={`tickets`} />
      {(user?.role === 'super_admin' || user?.role === 'admin') && <Dropdown row={row} />}
    </>)
  },
]

const priorityOpt = [
  { value: "", label: 'Select Priority' },
  { value: 'urgent', label: 'Urgent' },
  { value: 'high', label: 'High' },
  { value: 'normal', label: 'Normal' },
];
const statusOpt = [
  { value: "", label: 'Select Status' },
  { value: 'pending', label: 'Pending' },
  { value: 'ongoing', label: 'ongoing' },
  { value: 'completed', label: 'Completed' },
  { value: 'closed', label: 'Closed' },
];
const Tickets = () => {
  const { ticketList } = useSelector(state => state.ticket)
  const { user } = useSelector(state => state.auth)
  const { categoryList } = useSelector(state => state.category)
  const [categoryOpt, setCategoryOpt] = useState([{ id: 0, value: "", label: 'Select Categories' }])

  const [modal, setModal] = useState(false);
  const [images, setImages] = useState([]);
  const [filterText, setFilterText] = useState('');
  const inputRef = useRef(null)
  const inputRef1 = useRef(null)
  const inputRef2 = useRef(null)
  const toggleModal = (e) => {
    setImages(e)
    setModal((modal) => !modal);
  }

  if (modal) {
    document.body.classList.add("modal-active")
  }
  else {
    document.body.classList.remove("modal-active")

  }
  const initialFilter = useMemo(() => ({
    category: "",
    priority: "",
    status: "",
    startDate: "",
    endDate: ""
  }), [])

  const [filter, setFilter] = useState(initialFilter)

  const filterTickets = (tickets, params) => {
    return tickets.filter(ticket => {
      let includeTicket = true;

      if (params.category && ticket.category?.name !== params.category) {
        includeTicket = false;
      }
      if (params.priority && ticket.priority !== params.priority) {
        includeTicket = false;
      }
      if (params.status && ticket.status !== params.status) {
        includeTicket = false;
      }
      if (params.startDate && params.endDate) {
        const ticketDate = moment(ticket.createdAt);
        if (
          !ticketDate.isBetween(params.startDate, params.endDate, null, '[)') &&
          !ticketDate.isSame(params.endDate, 'day')
        ) {
          includeTicket = false;
        }
      }

      return includeTicket;
    });
  };
  const filteredItems = useMemo(() => {
    const filteredTickets = filterTickets(ticketList, filter);
    return customFilter(filteredTickets, [{ id: 'global-filter', value: filterText }], ['id', 'status', 'priority', 'category.name', 'address', 'priority', 'vendor.username', 'closed_on', 'id', 'createdAt']);
  }, [filterText, ticketList, filter]);

  useEffect(() => {
    const d_cate = { id: 0, value: "", label: 'Select Categories' }
    let category = categoryList.map(c => {
      return ({ id: c.id, value: c.name, label: c.name })
    })
    setCategoryOpt([d_cate, ...category])

  }, [categoryList, ticketList])

  const handleChange = useCallback((name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }))


  }, [])

  const handleReset = useCallback(() => {
    inputRef.current?.clearValue();
    inputRef1.current?.clearValue();
    inputRef2.current?.clearValue();
    setFilter(initialFilter)
    setFilterText('')

  }, [initialFilter])

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className='subheader gap-4'>
        <Search onFilter={(e) => setFilterText(e.target.value)} filterText={filterText} placeholder="Search tickets" />
        <div className="flex gap-4">
          <Calendar
            placeholder="Select Date"
            onChange={e => {
              let start = e[0]?.format();
              let end = e[1]?.format()
              setFilter((prev) => ({
                ...prev,
                startDate: start,
                endDate: end,
              }))
            }}
            id="selectdate"
            value={[filter.startDate, filter.endDate]}
            range
            rangeHover
            inputClass=' text-sm w-[120px]'
          />
          <Filter inputRef={inputRef} options={categoryOpt} name="category" onChange={handleChange} className='w-[175px] pointer f-bold' />
          <Filter inputRef={inputRef1} options={priorityOpt} name="priority" onChange={handleChange} className='w-[125px] pointer f-bold' />
          <Filter inputRef={inputRef2} options={statusOpt} name="status" onChange={handleChange} className='w-[125px] pointer f-bold' />
          <button onClick={handleReset} title="Reset all filters"><Reset height='25' width='25' className='font-[600]' /></button>
          {(user?.role !== 'super_admin' && user?.role !== 'admin') && <Link to="/tickets/create" className="flex">
            <Button classNames="bg-[#016268] h-full w-full text-[#fff] whitespace-nowrap ">Add New <Plus className="fill-white" /></Button>
          </Link>}
        </div>
      </div>
    );
  }, [filterText, handleChange, user?.role, handleReset, filter.endDate, filter.startDate, categoryOpt]);

  return (
    <>
      <DataTableComponent
        columns={columns(toggleModal, user)}
        data={filteredItems}

        subHeaderComponent={
          subHeaderComponentMemo
        }
        defaultSortFieldId="status"
        pagination
      />

      {modal && (<div className="modal z-30">
        <div className="overlay " onClick={toggleModal}></div>
        <ImagePreview images={images} />  </div>)}

    </>
  )
}

export default Tickets
