import { createSlice } from '@reduxjs/toolkit';
import { getTickets, setTickets, updateStatus, updateTickets } from './ticketAction';

const initialState = {
    loading: false,
    ticketList: [],
    ticketById: null,
    error: null
};

const userSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken;
            localStorage.setItem('accessToken', action.payload.accessToken);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTickets.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTickets.fulfilled, (state, action) => {
                state.loading = false;
                state.ticketList = action.payload.data;
                state.error = null;
            })
            .addCase(getTickets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
            .addCase(setTickets.pending, (state) => {
                state.loading = true;
            })
            .addCase(setTickets.fulfilled, (state, action) => {
                state.loading = false;
                state.ticketList = [...state.ticketList, { ...action.payload.data, image_before: JSON.stringify(action.payload.data.image_before) }];
                state.error = null;
            })
            .addCase(setTickets.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload?.error;
            })
            .addCase(updateTickets.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateTickets.fulfilled, (state, { payload }) => {
                state.loading = false;
                const updatedTicket = payload.updatedList;
                const updatedList = state.ticketList.map(ticket => {
                    if (ticket.id === updatedTicket.id) return updatedTicket;
                    return ticket;
                });
                state.ticketList = updatedList;
                state.error = null;
            })
            .addCase(updateTickets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
            .addCase(updateStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateStatus.fulfilled, (state, { payload }) => {
                state.loading = false;
                const updatedTicket = payload.data;
                const updatedList = state.ticketList.map(ticket => {
                    if (ticket.id === updatedTicket.id) {
                        return {
                            ...ticket,
                            status: updatedTicket.status,
                            closed_on: updatedTicket.closed_on
                        };

                    }
                    return ticket;
                });
                state.ticketList = updatedList;
                state.error = null;
            })
            .addCase(updateStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
    },
});

export default userSlice.reducer;

export const { updateUser } = userSlice.actions;
