import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import chatReducer from './chat/chatSlice';
import categoryReducer from './category/categorySlice';
import notificationReducer from './notification/notificationSlice';
import ticketReducer from './ticket/ticketSlice';
import { injectStore } from '../utils/axiosConfig';

const store = configureStore({
	reducer: {
		auth: authReducer,
		user: userReducer,
		category: categoryReducer,
		notification: notificationReducer,
		ticket: ticketReducer,
		chat:chatReducer,
	}
});

injectStore(store);

export default store;
