import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className="flex">
            <div
                className="flex-none w-64 bg-blue-600 p-6 text-white"
                style={{ zIndex: 500 }}
            >
                <h1 className="text-3xl font-semibold mb-4">Page Not Found</h1>
                <p className="text-sm">Oops! The page you're looking for doesn't exist.</p>
            </div>
            <div className="flex-col flex w-full">
                <div className="flex-grow bg-gray-100 p-6">
                    <h2 className="text-2xl font-semibold mb-2">{404}</h2>
                    <span className="text-gray-600">Page Not Found</span>
                </div>

                <div className="flex-grow bg-gray-100 p-6">
                    <span className="text-gray-600">To go home 👉 <Link to="/" aria-label='goHome' className='hover:underline'>Click here</Link>  </span>
                </div>
            </div>
        </div>
    )
}

export default NotFound