import React from 'react'

export const Button = ({classNames,...props}) => {
  return (
    <button className={` flex form-button justify-center items-center gap-2 px-3 rounded-[5px] text-[14px] ${classNames} transition-all `} {...props}>
      {props.children}
    </button>
  )
}


