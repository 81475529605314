import React from 'react'
import { Sidebar as ReactSideBar, Menu, MenuItem } from 'react-pro-sidebar';
import { Categories, Dashboard, DashboardLogo, Logout, Notification, Peoples, Properties, Tickets, Vendor } from '../assets';
import './layout.css'
import { NavLink, useNavigate } from 'react-router-dom';
import HasAccess from '../components/hasAccess/HasAccess';
import { logoutUser } from '../store/auth/authActions';
import { useDispatch } from 'react-redux';
import { successToast } from '../utils';
import Swal from 'sweetalert2';
const Sidebar = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const logoutHandler = async () => {
        Swal.fire({
            title: 'Warning!',
            text: "Are you sure, you want to log out?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#016268',
            cancelButtonColor: '#f1f1f1',
            focusCancel: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(logoutUser())
                await successToast("Come back soon! ")
                navigate("/")
                window.location.reload()
            }
        })
    };

    return (
        <ReactSideBar
            breakPoint="md"
            rootStyles={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                border: 'none',
                borderRadius: '0 10px 10px 0',
                background: '#fff',
                boxShadow: '0 0 10px #00000040',

            }}

        >
            <div className="sidebar_header h-24 p-5">
                <NavLink to="/" aria-label='Dashboard'>
                    <DashboardLogo className="w-[200px] h-14 sidebar-logo " />
                </NavLink>
            </div>
            <Menu className='relative sidebar-menu overflow-y-auto w-full pb-[70px]' >
                <MenuItem component={<NavLink to="/dashboard" />} icon={<Dashboard />} > Dashboard </MenuItem>
                <HasAccess permissions={"categories"}>
                    <MenuItem component={<NavLink to="/categories" />} icon={<Categories />}> Categories </MenuItem>
                </HasAccess>
                <HasAccess permissions={"tickets"}>
                    <MenuItem component={<NavLink to="/tickets" />} icon={<Tickets />}> Tickets </MenuItem>
                </HasAccess>
                <HasAccess permissions={"properties"}>
                    <MenuItem component={<NavLink to="/properties" />} icon={<Properties />}> Properties </MenuItem>
                </HasAccess>
                <HasAccess permissions={"vendors"}>
                    <MenuItem component={<NavLink to="/vendors" />} icon={<Vendor />}> Vendors </MenuItem>
                </HasAccess>
                <HasAccess permissions={"users"}>
                    <MenuItem component={<NavLink to="/users" />} icon={<Peoples />}> Users </MenuItem>
                </HasAccess>
                <HasAccess permissions={"notifications"}>
                    <MenuItem component={<NavLink to="/notifications" />} icon={<Notification />}> Notifications </MenuItem>
                </HasAccess>
            </Menu>
            <div className="sidebar_footer absolute bottom-0 shadow-inner w-full p-5 bg-white">
                <button type="button" onClick={logoutHandler} className="flex gap-4 text-inherit"><Logout /> Logout</button>
            </div>
        </ReactSideBar>
    )
}

export default Sidebar