import { useEffect, useRef, useState } from 'react'
import { Button, ProfileImg } from '../../components/controls'
import DropDown from '../../components/controls/Select'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { errorToast, promiseToast } from '../../utils'
import { getUser, updateUsers } from '../../store/user/userActions'

const UpdateUser = () => {
  const ref = useRef(null)
  const { id } = useParams()
  const { list } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialStates = {
    username: "",
    phone_number: "",
    email: "",
    password: "",
    module: []
  }
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [userData, setUserData] = useState(initialStates);

  const [imageFile, setImageFile] = useState(false)
  const [imagePreviewSrc, setImagePreviewSrc] = useState('')
  const [isImageSelected, setIsImageSelected] = useState('')
  const [imagePath, setImagePath] = useState("")

  useEffect(() => {
    let data = list.find(c => c.id === parseInt(id))

    setUserData({
      username: data?.username,
      phone_number: data?.phone_number,
      email: data?.email,
      module: data?.permissions?.map((e) => e.id),
    })
    let options = data?.permissions?.map((e) => e.name !== 'dashboard' && { id: e.id, value: e.name, label: e.name })

    setSelectedOptions(options)
    setImagePath(data?.profile_image)
  }, [list, id])

  const showImagePreviewAndValidate = (e) => {
    let selectedFile = ref.current.files.item(0)
    let supportedFormat = ["image/jpeg", "image/png", "image/svg+xml", "image/webp"]
    let size = selectedFile && parseFloat(selectedFile.size / 1024).toFixed(2);
    if (!selectedFile) return setIsImageSelected(false)
    if (!supportedFormat.includes(selectedFile.type)) {
      errorToast("Invalid file type. Only JPEG/PNG/icons are allowed.")
      return setIsImageSelected(false)
    }
    if (size > 5000) {
      e.target.value = ""
      setIsImageSelected(false)
      return errorToast("File size is more then 5 MB")
    }

    setImageFile(ref.current.files[0])
    let fileReader = new FileReader();
    fileReader.readAsDataURL(selectedFile);
    fileReader.addEventListener('load', (event) => {
      setImagePreviewSrc(event.target.result)
      setIsImageSelected(true)
    })
  }
  const clear = () => {
    setUserData(initialStates);
    setSelectedOptions([])
    showImagePreviewAndValidate(null)
    navigate(-1)
  }

  const handleChange = e => setUserData({ ...userData, [e.target.name]: e.target.value })
  const handleSubmit = async e => {
    e.preventDefault();
    let data = list.find(c => c.id === parseInt(id))
    let updateData = {}
    
    for (const property in userData) {
      if (userData.hasOwnProperty(property) && userData[property] !== data[property]) {
        updateData[property] = userData[property];
      }
    }
    await promiseToast("Updating User...", null, dispatch(updateUsers({ id, userData, file: imageFile })))
    let res = await dispatch(getUser())
    if (res.error) return;
    clear();
  }


  return (
    <form className='form-control p-8 flex gap-5 relative' onSubmit={handleSubmit}>
      <input hidden onChange={showImagePreviewAndValidate} ref={ref} data-max-size="500" type="file" accept="image/*" />
      <div className=" mt-5 w-96 flex justify-center cursor-pointer">
        {isImageSelected ?
          <ProfileImg src={imagePreviewSrc} onClick={() => { ref.current.click() }} className={"w-14 h-14 "} />
          :
          <ProfileImg image={imagePath} onClick={() => { ref.current.click() }} className={"w-14 h-14 "} />
        }
      </div>


      <div className="control-wrapper" >
        <label htmlFor="name">Name*</label>
        <input type="text" id='name' name='username' className='inputField' required onChange={handleChange} value={userData.username || ""} />
      </div>
      <div className="control-wrapper">
        <label htmlFor="phone_number">Contact No.*</label>
        <input type="number" id='phone_number' name='phone_number' className='inputField' required onChange={handleChange} value={userData.phone_number || ""} />
      </div>
      <div className="control-wrapper">
        <label htmlFor="email">Email ID*</label>
        <input type="text" id='email' name='email' className='inputField' required onChange={handleChange} value={userData.email || ""} />
      </div>

      <div className="control-wrapper">
        <label htmlFor="access">Select Access*</label>
        <DropDown setUserData={setUserData} className="z-50 cursor-pointer" selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />
      </div>
      <div className="buttons absolute bottom-[-75px] left-0 flex h-[50px] w-[300px] gap-5">
        <Button type='submit' classNames="bg-[#016268] h-[50px] w-[150px] text-[#fff] ">Submit</Button>
        <Button type='reset' classNames="bg-[#F1F1F1] h-[50px] w-[150px]" onClick={clear} >Cancel</Button>
      </div>

    </form>
  )
}

export default UpdateUser
