import useAccessControl from '../../utils';
const HasAccess = ({
    children,
    permissions,
    renderAuthFailed,
}) => {

    const { checkRoleAccess } = useAccessControl();
    const hasRequiredPermission = checkRoleAccess(["super_admin", "admin", "manager", "vendor"], [permissions])

    // If the user has the required role and permission, render the children
    if (hasRequiredPermission) return children;

    // If the user does not have the required role and/or permission, render the authentication failed message
    return renderAuthFailed || null;
};

export default HasAccess;
