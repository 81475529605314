import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Logo, Email, Password, View } from '../../assets';
import { loginUser } from '../../store/auth/authActions';
import { promiseToast } from '../../utils';
import './login.css'
export const Login = () => {
	const { user, loading } = useSelector((state) => state.auth);
	const [showPassword, setShowPassword] = useState(false)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let email = useRef('');
	let password = useRef('');

	useEffect(() => {
		if (user) {
			navigate('/', { successLogin: true });
		}
	}, [navigate, user]);

	const signinUser = async (e) => {
		e.preventDefault();
		let res = await promiseToast("Validating...", null, dispatch(loginUser({ email: email.value, password: password.value })))
		if (res.error) return;
	};

	return (
		<section className="bg-gray-50 dark:bg-gray-900 ">
			<div className="flex flex-col items-center bg-[#f1f1f1] justify-center px-6 py-4 mx-auto md:h-screen lg:py-0">

				<div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 relative ">
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
						<div className="logo absolute top-[-100px] left-[50%] translate-x-[-50%] ">
							<Logo className="w-[200px] h-[75px]  " />
						</div>
						<h1 className="text-xl font-[500] leading-tight tracking-tight text-gray-900 md:text-3xl dark:text-white text-center">
							Log in to access your account
						</h1>
						<form className="space-y-4 md:space-y-4" onSubmit={signinUser}>
							<div className='inputWrapper flex items-center justify-center gap-2 bg-[#F7F7F7] w-full p-3 rounded-[5px]'>
								<Email height={20} width={20} />
								<input className="focus:outline-0 w-full bg-transparent font-[500] " name="email" placeholder="Email" id="email" ref={(e) => { email = e; }} type="email" required />
							</div>
							<div>
								<div className='inputWrapper flex items-center justify-center gap-2 bg-[#F7F7F7] w-full p-3 rounded-[5px]'>
									<Password height={20} width={20} />
									<input className="focus:outline-0 w-full bg-transparent font-[500] " name="password" id="password" ref={(e) => { password = e; }} type={showPassword ? "text" : "password"} placeholder="Password" required />
									<button type='button' aria-label="hide_show" onClick={() => setShowPassword(prev => !prev)} className='relative flex items-center justify-center '>
										<View width={20} height={20} />
										<div className={`line ${showPassword ? 'hide-line' : ''}`}></div>
									</button>
								</div>
							</div>
							<button type="submit" className="primaryButton">
								{loading ? 'logging...' : 'Log In'}
							</button>
							<div className="flex items-center justify-center">
								<NavLink to="/forgot" className="text-sm link">Forgot Password?</NavLink>
							</div>

						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Login