import React from 'react'
import './controls.css'
import { SearchIcon } from '../../assets';
const Search = ({ onFilter, filterText, placeholder }) => {
  
  return (
    <div className="search flex items-center">
      <input
        type="text"
        placeholder={placeholder}
        value={filterText}
        onChange={onFilter}
        className='focus:outline-0'
      />
      <div>
        <SearchIcon style={{fill:"#2B2B2B"}}/>
      </div>
    </div>
  );
}

export default Search
